<app-navbar></app-navbar>
<app-snack></app-snack>
<div class="container-fluid c-details-container">
  <div class="row c-details-row">
    <div class="col c-details-items-container d-flex flex-column">
      <div
        class="d-flex flex-wrap justify-content-between c-details-title-wrap"
      >
        <p class="c-details-title mb-1">{{ selectedProject.title }}</p>
        <a routerLink="/projects" class="mb-1 align-self-end c-details-link"
          >BACK TO PROJECTS</a
        >
      </div>
      <div class="c-details-category d-flex flex-nowrap align-items-center">
        <span
          *ngIf="projectsLoading"
          class="spinner-border my-3 d-block mx-auto"
        ></span>
        <div
          class="c-details-category-item d-flex justify-content-center align-items-center"
          *ngFor="let project of projectsResult"
          (click)="projectClicked(project.id)"
        >
          <p class="mb-0 text-center">
            {{ project.title }}
          </p>
        </div>
      </div>
      <div class="mt-3">
        <div>
          <p class="mb-0 c-details-content-title">
            CLIENT: {{ selectedProject.title }}
          </p>
          <p class="mb-0 c-details-content-title">information about design</p>
        </div>
        <div class="c-details-bottom-container">
          <swiper class="mb-3" [config]="detailsSlidesConfig">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                *ngFor="let image of selectedProject.images"
                data-scale="2"
              >
                <file-loader
                  class="swiper-img"
                  [imageId]="image"
                  (mouseover)="onImageMouseover()"
                ></file-loader>
              </div>
              <div
                *ngIf="selectedProject.video_url != null"
                class="swiper-slide"
              >
                <video
                  [attr.src]="videoSrc"
                  controls
                  width="100%"
                  height="100%"
                ></video>
              </div>
            </div>

            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </swiper>
          <quill-view-html
            theme="snow"
            [content]="selectedProject.text"
          ></quill-view-html>
        </div>
      </div>
    </div>
  </div>
</div>
