import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AddMessageRequest } from "src/app/models/messages/add-message-request";
import { Observable } from "rxjs";
import { MessagesResponse } from "src/app/models/messages/messages-response";

@Injectable({
  providedIn: "root",
})
export class MessagesService {
  constructor(private http: HttpClient) {}

  addMessage(addMessageRequest: AddMessageRequest): Observable<string> {
    const url = "/api/messages";
    const body = addMessageRequest;
    return this.http.post<string>(url, body);
  }

  getMessages(): Observable<MessagesResponse[]> {
    const url = "/api/messages";
    return this.http.get<MessagesResponse[]>(url);
  }

  messageDetails(messageId: string): Observable<MessagesResponse> {
    const url = "/api/messages/" + messageId;
    return this.http.get<MessagesResponse>(url);
  }

  updateMessage(messageId: string, is_read: string): Observable<string> {
    const url = "/api/messages/" + messageId;
    return this.http.put<string>(url, { is_read });
  }
}
