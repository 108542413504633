<app-snack></app-snack>
<div class="c-navbar-container">
  <div
    class="d-flex flex-wrap justify-content-between align-items-center c-navbar-top-container"
  >
    <a
      class="c-navbar-sidenav-button"
      (click)="sideNavCheck == true ? closeSideNav() : openSideNav()"
    >
      <i class="fas fa-bars"></i>
    </a>
    <a href="/"
      ><img class="c-navbar-logo" src="../../../assets/pngs/mylogo.png"
    /></a>
    <button class="btn c-navbar-button" (click)="logoutClicked()">
      Log out
    </button>
  </div>
  <div class="c-navbar-sidenav-container" id="sideNav">
    <a [routerLink]="['/panel/about-me']" class="c-navbar-sidenav-link">
      <i class="fas fa-user"></i>
      About Me</a
    >
    <a [routerLink]="['/panel/category']" class="c-navbar-sidenav-link">
      <i class="fas fa-map"></i>
      Categories</a
    >
    <a [routerLink]="['/panel/projects']" class="c-navbar-sidenav-link">
      <i class="fas fa-file"></i>
      Projects
    </a>
    <a [routerLink]="['/panel/brands']" class="c-navbar-sidenav-link">
      <i class="fas fa-image"></i>
      Brands
    </a>
    <a [routerLink]="['/panel/messages']" class="c-navbar-sidenav-link">
      <i class="fas fa-comments"></i>
      Messages
    </a>
  </div>
  <div class="c-content-container" id="navContainer">
    <div class="container-fluid">
      <div class="row c-add-category-container">
        <div class="col-12">
          <span
            *ngIf="loading"
            class="spinner-border my-3 d-block mx-auto"
          ></span>
          <h5 *ngIf="!loading">
            Edit Category: {{ categoryDetailResult.title }}
          </h5>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12 mb-3" *ngIf="!loading">
          <p>Current Image:</p>
          <div class="c-category-image">
            <file-loader [imageId]="uploadResponse"></file-loader>
          </div>
        </div>
        <div class="col-xl-8 col-lg-12 col-md-12" *ngIf="!loading">
          <input
            type="text"
            class="form-control c-category-input c-category-input-width-fix"
            placeholder="Title"
            [(ngModel)]="titleInput"
          />
          <label class="mt-3">Type:</label>
          <select
            class="form-control c-category-input c-category-input-width-fix"
            (ngModelChange)="selectChange($event)"
            [ngModel]="selectValue"
          >
            <option [value]="true">Color</option>
            <option [value]="false">Image</option>
          </select>
          <input
            type="text"
            class="form-control c-category-input c-category-input-width-fix mt-3"
            placeholder="Color (Hex: #xxxxxx)"
            *ngIf="inputRender == true"
            [(ngModel)]="colorInput"
          />
          <span
            *ngIf="uploadLoading"
            class="spinner-border my-3 d-block"
          ></span>
          <button
            class="btn c-category-button d-block mt-3"
            (click)="deleteImageClicked()"
            *ngIf="inputRender == false"
            [disabled]="deleteImageDisabled"
            type="button"
          >
            Delete Image
          </button>
          <button
            class="btn c-category-button d-block mt-3"
            (click)="fileInput.click()"
            *ngIf="inputRender == false"
            [disabled]="uploadDisabled"
            type="button"
          >
            Upload
          </button>
          <input
            type="file"
            class="d-none"
            #fileInput
            (change)="onFileSelected($event)"
            [disabled]="uploadDisabled"
          />
        </div>
        <div class="col-12" *ngIf="!loading">
          <span
            *ngIf="updateLoading"
            class="spinner-border my-3 d-block mx-auto"
          ></span>
          <button
            class="btn c-category-button d-block mx-auto"
            (click)="editCategoryClicked()"
            [disabled]="updateLoading"
            type="button"
          >
            Submit Changes
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
