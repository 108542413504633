import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MainComponent } from "./views/main/main.component";
import { AboutMeComponent } from "./views/about-me/about-me.component";
import { ProjectsComponent } from "./views/projects/projects.component";
import { ProjectDetailsComponent } from "./views/project-details/project-details.component";
import { BrandsComponent } from "./views/brands/brands.component";
import { ContactMeComponent } from "./views/contact-me/contact-me.component";
import { PanelLoginComponent } from "./panel/panel-login/panel-login.component";
import { PanelCategoryComponent } from "./panel/panel-category/panel-category.component";
import { PanelAboutMeComponent } from "./panel/panel-about-me/panel-about-me.component";
import { PanelProjectsComponent } from "./panel/panel-projects/panel-projects.component";
import { PanelAddProjectComponent } from "./panel/panel-add-project/panel-add-project.component";
import { PanelMessagesComponent } from "./panel/panel-messages/panel-messages.component";
import { PanelBrandsComponent } from "./panel/panel-brands/panel-brands.component";
import { PanelMessagesDetailComponent } from "./panel/panel-messages-detail/panel-messages-detail.component";
import { AuthGuard } from "./utils/auth/auth.guard";
import { NotFoundComponent } from "./views/not-found/not-found.component";
import { PanelEditCategoryComponent } from "./panel/panel-edit-category/panel-edit-category.component";
import { PanelEditProjectComponent } from "./panel/panel-edit-project/panel-edit-project.component";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    component: MainComponent,
  },
  {
    path: "about",
    component: AboutMeComponent,
  },
  {
    path: "projects",
    component: ProjectsComponent,
  },
  {
    path: "projects/:id",
    component: ProjectDetailsComponent,
  },
  {
    path: "brands",
    component: BrandsComponent,
  },
  {
    path: "contact-me",
    component: ContactMeComponent,
  },
  // {
  //   path: "panel",
  //   redirectTo: "panel/about-me",
  // },
  {
    path: "panel/login",
    component: PanelLoginComponent,
  },
  {
    path: "panel/category",
    component: PanelCategoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "panel/category/edit/:catId",
    component: PanelEditCategoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "panel/about-me",
    component: PanelAboutMeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "panel/projects",
    component: PanelProjectsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "panel/edit-project/:projectId",
    component: PanelEditProjectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "panel/add-project",
    component: PanelAddProjectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "panel/brands",
    component: PanelBrandsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "panel/messages",
    component: PanelMessagesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "panel/messages/:messageId",
    component: PanelMessagesDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
