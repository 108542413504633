import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { UploadResponse } from "src/app/models/file/upload-response";
import { map } from "rxjs/operators";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class FileService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  upload(formData: FormData): Observable<UploadResponse> {
    const currentUser = this.authService.currentUserValue;
    const token = currentUser ? currentUser.token : "";
    const url = "/api/files/" + "?access_token=" + token;
    const body = formData;
    return this.http.post<UploadResponse>(url, body);
  }

  loadFile(imageId: string): Observable<any> {
    const token = "dGVzdA==";

    const url = "/api/files/" + imageId + "?access_token=" + token;
    return this.http.get(url, { responseType: "blob" }).pipe(
      map((x) => {
        return {
          id: imageId,
          image: x,
        };
      })
    );
  }
}
