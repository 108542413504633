<app-snack></app-snack>
<div class="c-navbar-container">
  <div
    class="d-flex flex-wrap justify-content-between align-items-center c-navbar-top-container"
  >
    <a
      class="c-navbar-sidenav-button"
      (click)="sideNavCheck == true ? closeSideNav() : openSideNav()"
    >
      <i class="fas fa-bars"></i>
    </a>
    <a href="/"
      ><img class="c-navbar-logo" src="../../../assets/pngs/mylogo.png"
    /></a>
    <button class="btn c-navbar-button" (click)="logoutClicked()">
      Log out
    </button>
  </div>
  <div class="c-navbar-sidenav-container" id="sideNav">
    <a [routerLink]="['/panel/about-me']" class="c-navbar-sidenav-link">
      <i class="fas fa-user"></i>
      About Me</a
    >
    <a [routerLink]="['/panel/category']" class="c-navbar-sidenav-link">
      <i class="fas fa-map"></i>
      Categories</a
    >
    <a [routerLink]="['/panel/projects']" class="c-navbar-sidenav-link">
      <i class="fas fa-file"></i>
      Projects
    </a>
    <a
      [routerLink]="['/panel/brands']"
      [routerLinkActive]="['c-navbar-active']"
      class="c-navbar-sidenav-link"
    >
      <i class="fas fa-image"></i>
      Brands
    </a>
    <a [routerLink]="['/panel/messages']" class="c-navbar-sidenav-link">
      <i class="fas fa-comments"></i>
      Messages
    </a>
  </div>
  <div class="c-content-container" id="navContainer">
    <div class="container-fluid">
      <div class="row c-brands-container">
        <div class="col-xl-3 col-lg-12 col-md-12">
          <h5>Brands:</h5>
          <span
            *ngIf="loading"
            class="spinner-border my-3 d-block mx-auto"
          ></span>
          <button
            class="btn c-brands-button d-block mx-auto"
            (click)="uploadInput.click()"
            [disabled]="loading"
          >
            Upload Image
          </button>
          <input
            type="file"
            class="d-none"
            #uploadInput
            (change)="onFileSelected($event)"
          />
        </div>
        <div class="col-xl-9 col-lg-12 col-md-12 c-brands-wrap">
          <span
            *ngIf="tableLoading"
            class="spinner-border my-3 d-block mx-auto"
          ></span>
          <div *ngIf="!tableLoading" class="table-responsive">
            <table class="table c-brands-table">
              <tr>
                <th class="c-brands-table-head-left">Index</th>
                <th>Brand Image</th>
                <th class="c-brands-table-head-right">#</th>
              </tr>
              <tr *ngFor="let brand of brandsResult; let i = index">
                <td>{{ i + 1 }}</td>
                <td>
                  <div class="c-brands-image-container mx-auto">
                    <file-loader [imageId]="brand.image_id"></file-loader>
                  </div>
                </td>
                <td>
                  <button
                    class="btn c-brands-button"
                    (click)="deleteBrand(i, brand.id)"
                    [disabled]="deleteLoading"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
