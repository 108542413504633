import { Component, OnInit } from "@angular/core";
import { MessagesService } from "src/app/services/messages/messages.service";
import { AlertService } from "src/app/services/alert/alert.service";
import { AddMessageRequest } from "src/app/models/messages/add-message-request";
import { AboutService } from "src/app/services/about/about.service";

@Component({
  selector: "app-contact-me",
  templateUrl: "./contact-me.component.html",
  styleUrls: ["./contact-me.component.scss"],
})
export class ContactMeComponent implements OnInit {
  nameInput: string;
  emailInput: string;
  messageInput: string;
  loading = false;
  submitDisabled = false;
  email: string = null;
  mobileNumber: string = null;

  constructor(
    private alertService: AlertService,
    private messagesService: MessagesService,
    private aboutService: AboutService
  ) {}

  ngOnInit(): void {
    document.body.style.backgroundColor = "#fff";

    this.aboutService.getAbout().subscribe(
      (res) => {
        this.email = res.email;
        this.mobileNumber = res.phone;
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
      }
    );
  }

  messageSubmitClicked() {
    if (this.nameInput == null) {
      this.alertService.error("You must fill name input.");
      return;
    }

    if (this.emailInput == null) {
      this.alertService.error("You must fill email input.");
      return;
    }

    if (this.messageInput == null) {
      this.alertService.error("You must fill message input.");
      return;
    }

    this.loading = true;
    this.messagesService
      .addMessage(
        new AddMessageRequest(
          this.nameInput,
          this.emailInput,
          this.messageInput
        )
      )
      .subscribe(
        (res) => {
          this.alertService.success("Message submited.");
          this.loading = false;
          this.submitDisabled = true;
        },
        (error) => {
          console.log(error);
          this.alertService.error(error);
          this.loading = false;
          this.submitDisabled = false;
        }
      );
  }
}
