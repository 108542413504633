import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth/auth.service";
import { ProjectService } from "src/app/services/project/project.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ProjectsResponse } from "src/app/models/project/projects-response";
import { AlertService } from "src/app/services/alert/alert.service";
import { CategoryService } from "src/app/services/category/category.service";
import { CategoriesResponse } from "src/app/models/category/categories-response";
import { FileService } from "src/app/services/file/file.service";
import { AddProjectRequest } from "src/app/models/project/add-project-request";

@Component({
  selector: "app-panel-edit-project",
  templateUrl: "./panel-edit-project.component.html",
  styleUrls: ["./panel-edit-project.component.scss"],
})
export class PanelEditProjectComponent implements OnInit {
  sideNavCheck = false;
  paramId: string;
  loading = false;
  projectDetailResult: ProjectsResponse = new ProjectsResponse(
    null,
    null,
    null,
    null,
    null,
    null
  );
  categoriesResult: CategoriesResponse[] = [];
  categoryLoading = false;
  selectValue: number;
  titleInput: string;
  uploadLoading = false;
  imageResponse: string[] = [];
  textInput: string;
  videoUploadResponse: string;
  videoUploadLoading = false;
  videoUploadDisable = false;
  updateLoading = false;
  deleteImagesDisabled = false;
  uploadDisabled = false;
  deleteVideoDisabled = false;

  constructor(
    private authService: AuthService,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private categoryService: CategoryService,
    private fileService: FileService
  ) {}

  ngOnInit(): void {
    document.body.style.backgroundColor = "#f2f2f2";

    this.paramId = this.activatedRoute.snapshot.paramMap.get("projectId");

    this.getCategories();
    this.getProjectDetails();
  }

  getCategories() {
    this.categoryLoading = true;
    this.categoryService.getCategories().subscribe(
      (res) => {
        this.categoriesResult = res;
        this.categoryLoading = false;
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
        this.categoryLoading = false;
      }
    );
  }

  getProjectDetails() {
    this.loading = true;
    this.projectService.getProjects().subscribe(
      (res) => {
        this.projectDetailResult = res.find((x) => x.id == this.paramId);
        console.log(this.projectDetailResult);
        this.titleInput = this.projectDetailResult.title;
        this.imageResponse = this.projectDetailResult.images;
        this.textInput = this.projectDetailResult.text;
        this.videoUploadResponse = this.projectDetailResult.video_url;
        this.selectValue = this.projectDetailResult.category_id;
        if (this.imageResponse.length != 0) {
          this.deleteImagesDisabled = false;
        } else {
          this.deleteImagesDisabled = true;
        }
        if (this.videoUploadResponse != null) {
          this.deleteVideoDisabled = false;
        } else {
          this.deleteVideoDisabled = true;
        }
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
        this.loading = false;
      }
    );
  }

  deleteImagesClicked() {
    this.deleteImagesDisabled = true;
    this.imageResponse = [];
  }

  deleteVideoClicked() {
    this.deleteVideoDisabled = true;
    this.videoUploadResponse = null;
  }

  selectChange(event) {
    const value = event.target.value;
    this.selectValue = parseInt(value);
  }

  onFileSelected(event) {
    this.uploadLoading = true;
    const files = event.target.files;
    if (files) {
      for (const file of files) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          // this.uploadedImages.push(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    }
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i], files[i]["name"]);
    }
    this.fileService.upload(formData).subscribe(
      (res) => {
        console.log(res);
        this.alertService.success("Image uploaded successfully.");
        this.imageResponse.push(res.id);
        this.uploadLoading = false;
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
        this.uploadLoading = false;
      }
    );
  }

  onFileSelectedVideo(event) {
    const files = event.target.files;
    if (files) {
      for (const file of files) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
      }
    }
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i], files[i]["name"]);
    }
    this.videoUploadLoading = true;
    this.fileService.upload(formData).subscribe(
      (res) => {
        this.alertService.success("Video uploaded successfully.");
        this.videoUploadResponse = res.id;
        this.videoUploadLoading = false;
        this.videoUploadDisable = true;
      },
      (error) => {
        console.log("upload error", error);
        this.alertService.error(error);
        this.videoUploadLoading = false;
        this.videoUploadDisable = false;
      }
    );
  }

  editProjectClicked() {
    if (this.titleInput == null) {
      this.alertService.error("Please fill title input.");
      return;
    }

    if (this.selectValue == null) {
      this.alertService.error("Please select a category.");
      return;
    }

    if (this.imageResponse.length < 1) {
      this.alertService.error("Please upload an image.");
      return;
    }

    this.updateLoading = true;
    this.projectService
      .updateProject(
        this.paramId,
        new AddProjectRequest(
          this.imageResponse,
          this.titleInput,
          this.textInput,
          this.videoUploadResponse,
          this.selectValue
        )
      )
      .subscribe(
        (res) => {
          this.updateLoading = false;
          this.alertService.success("Project successfully updated.");
          this.router.navigate(["/panel/projects"]);
        },
        (error) => {
          console.log(error);
          this.updateLoading = false;
          this.alertService.error(error);
        }
      );
  }

  openSideNav() {
    document.getElementById("sideNav").style.width = "300px";
    document.getElementById("navContainer").style.marginLeft = "300px";
    this.sideNavCheck = true;
  }

  closeSideNav() {
    document.getElementById("sideNav").style.width = "0";
    document.getElementById("navContainer").style.marginLeft = "0";
    this.sideNavCheck = false;
  }

  logoutClicked() {
    this.authService.logout();
  }
}
