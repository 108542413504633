export class UpdateAboutRequest {
  text: string;
  image_id: string;
  email: string;
  phone: string;

  constructor(text: string, image_id: string, email: string, phone: string) {
    this.text = text;
    this.image_id = image_id;
    this.email = email;
    this.phone = phone;
  }
}
