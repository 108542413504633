import { Component, OnInit } from "@angular/core";
import { AlertService } from "src/app/services/alert/alert.service";
import { MessagesService } from "src/app/services/messages/messages.service";
import { MessagesResponse } from "src/app/models/messages/messages-response";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: "app-panel-messages",
  templateUrl: "./panel-messages.component.html",
  styleUrls: ["./panel-messages.component.scss"],
})
export class PanelMessagesComponent implements OnInit {
  sideNavCheck = false;
  loading = false;
  messagesResult: MessagesResponse[] = [];

  constructor(
    private alertService: AlertService,
    private messagesService: MessagesService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    document.body.style.backgroundColor = "#f2f2f2";

    this.loading = true;
    this.messagesService.getMessages().subscribe(
      (res) => {
        this.messagesResult = res;
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
        this.loading = false;
      }
    );
  }

  openSideNav() {
    document.getElementById("sideNav").style.width = "300px";
    document.getElementById("navContainer").style.marginLeft = "300px";
    this.sideNavCheck = true;
  }

  closeSideNav() {
    document.getElementById("sideNav").style.width = "0";
    document.getElementById("navContainer").style.marginLeft = "0";
    this.sideNavCheck = false;
  }

  logoutClicked() {
    this.authService.logout();
  }
}
