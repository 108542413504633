<div class="c-navbar">
  <a (click)="navbarOpener()">
    <svg
      class="c-navbar-button"
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="500px"
      height="500px"
    >
      <path
        d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z"
      />
    </svg>
  </a>
  <div class="c-navbar-overlay" id="navbarOverlay">
    <a (click)="navbarCloser()" class="c-navbar-overlay-close-icon">&times;</a>
    <div class="c-navbar-overlay-content">
      <div>
        <a routerLink="/" class="c-navbar-overlay-link">HOME</a>
      </div>
      <div>
        <a routerLink="/about" class="c-navbar-overlay-link">ABOUT ME</a>
      </div>
      <div>
        <a routerLink="/projects" class="c-navbar-overlay-link">PROJECTS</a>
      </div>
      <div>
        <a routerLink="/brands" class="c-navbar-overlay-link">BRANDS</a>
      </div>
      <div>
        <a routerLink="/contact-me" class="c-navbar-overlay-link">CONTACT ME</a>
      </div>
    </div>
  </div>
  <a
    href=""
    class="c-navbar-logo d-flex justify-content-center align-items-center"
  >
    <img src="../../../assets/pngs/mylogo.png" />
  </a>
  <div class="c-navbar-social-media">
    <p class="d-inline-block mb-0 mr-3 c-navbar-social-title">FOLLOW ME ON</p>
    <div class="d-inline-block">
      <a
        class="mr-2"
        href="https://ir.linkedin.com/in/nastaran-shivaee-6a719768"
      >
        <svg
          class="c-navbar-social-media-icon"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 195 195"
          style="enable-background: new 0 0 195 195;"
          xml:space="preserve"
        >
          <g>
            <g>
              <g>
                <path
                  class="st0"
                  d="M97.7,191.5c-52,0-94.3-42.3-94.3-94.3c0-52,42.3-94.3,94.3-94.3c52,0,94.4,42.3,94.4,94.3
				C192,149.1,149.7,191.5,97.7,191.5z M97.7,12.9c-46.4,0-84.2,37.8-84.2,84.2s37.8,84.2,84.2,84.2s84.2-37.8,84.2-84.2
				C181.9,50.7,144.1,12.9,97.7,12.9z"
                />
              </g>
              <g>
                <g>
                  <path
                    class="st0"
                    d="M151,104.3v35.2c0,0.9-0.7,1.6-1.6,1.6h-18.2c-0.9,0-1.6-0.7-1.6-1.6v-32.8c0-8.6-3.1-14.5-10.8-14.5
					c-5.9,0-9.4,4-10.9,7.8c-0.6,1.4-0.7,3.3-0.7,5.2v34.3c0,0.9-0.7,1.6-1.6,1.6H87.4c-0.9,0-1.6-0.7-1.6-1.6c0-8.7,0.2-51.1,0-61
					c0-0.9,0.7-1.6,1.6-1.6h18.1c0.9,0,1.6,0.7,1.6,1.6V86c0,0.1-0.1,0.1-0.1,0.2h0.1V86c2.8-4.4,7.9-10.6,19.2-10.6
					C140.5,75.4,151,84.6,151,104.3L151,104.3z M54.3,141.1h18.2c0.9,0,1.6-0.7,1.6-1.6v-61c0-0.9-0.7-1.6-1.6-1.6H54.3
					c-0.9,0-1.6,0.7-1.6,1.6v61C52.7,140.4,53.4,141.1,54.3,141.1z"
                  />
                </g>
              </g>
            </g>
            <g>
              <circle class="st0" cx="62.8" cy="57.3" r="11.4" />
            </g>
          </g>
        </svg>
      </a>
      <a class="mr-2" href="https://m.facebook.com/nastaran.shivaee/">
        <svg
          class="c-navbar-social-media-icon"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 195 195"
          style="enable-background: new 0 0 195 195;"
          xml:space="preserve"
        >
          <g>
            <g>
              <path
                class="st0"
                d="M97.4,192.1c-52.1,0-94.5-42.4-94.5-94.5S45.3,3,97.4,3S192,45.4,192,97.6C192,149.7,149.6,192.1,97.4,192.1z
			 M97.4,13.2c-46.5,0-84.4,37.8-84.4,84.4c0,46.5,37.8,84.4,84.4,84.4s84.4-37.8,84.4-84.4C181.8,51,144,13.2,97.4,13.2z"
              />
            </g>
            <g>
              <path
                class="st1"
                d="M82.6,98v54.8c0,0.8,0.6,1.4,1.4,1.4h20.3c0.8,0,1.4-0.6,1.4-1.4V97.1h14.8c0.7,0,1.4-0.6,1.4-1.3l1.4-16.8
			c0.1-0.8-0.6-1.6-1.4-1.6h-16.2V65.6c0-2.8,2.3-5,5.1-5h11.4c0.8,0,1.4-0.6,1.4-1.4V42.3c0-0.8-0.6-1.4-1.4-1.4H103
			c-11.3,0-20.4,9.1-20.4,20.4v16.2H72.5c-0.8,0-1.4,0.6-1.4,1.4v16.8c0,0.8,0.6,1.4,1.4,1.4h10.2L82.6,98L82.6,98z"
              />
            </g>
          </g>
        </svg>
      </a>
      <a href="https://www.instagram.com/nastaran.shivaee/?hl=en">
        <svg
          class="c-navbar-social-media-icon"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 195 195"
          style="enable-background: new 0 0 195 195;"
          xml:space="preserve"
        >
          <g>
            <path
              class="st0"
              d="M96.7,191.1c-51.8,0-93.9-42.1-93.9-93.9S44.9,3.3,96.7,3.3s93.9,42.1,93.9,93.9
		C190.6,148.9,148.5,191.1,96.7,191.1z M96.7,12.2c-46.9,0-85,38.1-85,85s38.1,85,85,85s85-38.1,85-85S143.6,12.2,96.7,12.2z
		 M128.4,59.1c-3.8,0-7,3.1-7,6.9s3.1,6.9,7,6.9c3.8,0,6.9-3.1,6.9-6.9C135.3,62.2,132.2,59.1,128.4,59.1z M97.5,68
		c-16.1,0-29.2,13.1-29.2,29.2s13.1,29.2,29.2,29.2s29.2-13.1,29.2-29.2S113.6,68,97.5,68z M97.5,115.9c-10.3,0-18.7-8.4-18.7-18.7
		s8.4-18.7,18.7-18.7s18.7,8.4,18.7,18.7C116.2,107.5,107.8,115.9,97.5,115.9z M156.4,72.9c0-19.7-15.9-35.6-35.6-35.6h-47
		c-19.7,0-35.6,15.9-35.6,35.6v47c0,19.7,15.9,35.6,35.6,35.6h47c19.7,0,35.6-15.9,35.6-35.6V72.9z M145.2,119.9
		c0,13.5-10.9,24.4-24.5,24.4h-47c-13.5,0-24.4-10.9-24.4-24.4v-47c0-13.5,10.9-24.4,24.4-24.4h47c13.5,0,24.5,10.9,24.5,24.4V119.9
		z"
            />
          </g>
        </svg>
      </a>
    </div>
  </div>
</div>

<app-snack></app-snack>
<svg
  class="c-down-arrow"
  version="1.1"
  id="a-arrow-down"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  width="960px"
  height="560px"
  viewBox="0 0 960 560"
  enable-background="new 0 0 960 560"
  xml:space="preserve"
>
  <g id="Rounded_Rectangle_33_copy_4_1_">
    <path
      d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937
		c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937
		c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
    />
  </g>
</svg>

<svg
  class="c-up-arrow"
  (click)="scrollToTop()"
  version="1.1"
  id="a-arrow-up"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  width="960px"
  height="560px"
  viewBox="0 0 960 560"
  enable-background="new 0 0 960 560"
  xml:space="preserve"
>
  <g id="Rounded_Rectangle_33_copy_4_1_">
    <path
      d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937
		c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937
		c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
    />
  </g>
</svg>

<div
  class="c-first-section a-section d-flex flex-column justify-content-center align-items-center"
  id="a-first-animation"
>
  <img
    id="a-first-animation-name"
    class="c-first-animation-name"
    src="../../../assets/new_pngs/name.png"
  />
  <img
    id="a-first-animation-start"
    class="c-first-animation c-first-animation-start"
    src="../../../assets/sections/P1_P2_egg_start.png"
  />
  <img
    id="a-first-animation-wait"
    class="c-first-animation c-first-animation-wait"
    src="../../../assets/sections/P1_P2_egg_wait.png"
  />
</div>

<div class="c-second-section a-section d-flex flex-wrap">
  <div
    class="c-second-middle-container d-flex flex-column justify-content-center align-items-center"
  >
    <div class="c-second-animation"></div>
    <div class="c-mob-second"></div>
    <div class="c-third-animation"></div>
    <div class="c-mob-third"></div>
    <div class="c-fourth-animation"></div>
    <div class="c-mob-fourth"></div>
    <div class="c-fifth-animation"></div>
    <div class="c-mob-fifth"></div>
    <div id="a-anim-end" class="c-animation-end"></div>
    <div id="a-anim-end-mob" class="c-mob-end"></div>
    <!-- <img
      class="c-second-animation c-second-animation-start"
      id="a-second-animation-start"
      src="../../../assets/sections/P3_character_start.gif"
    />
    <img
      class="c-second-animation c-second-animation-wait"
      id="a-second-animation-wait"
      src="../../../assets/sections/P3_character_wait.gif"
    /> -->
    <!-- <img
      src="../../../assets/sections/P4_character_enter.gif"
      id="a-third-animation-start"
      class="c-third-animation c-third-animation-start"
    />
    <img
      src="../../../assets/sections/P4_character_wait.gif"
      id="a-third-animation-wait"
      class="c-third-animation c-third-animation-wait"
    /> -->
    <!-- <img
      src="../../../assets/sections/P5_character_start.gif"
      id="a-fourth-animation-start"
      class="c-fourth-animation c-fourth-animation-start"
    /> -->
    <!-- <img
      src="../../../assets/sections/P6_character_enter.gif"
      id="a-fifth-animation-start"
      class="c-fifth-animation c-fifth-animation-start"
    />
    <img
      src="../../../assets/sections/P6_character_wait.gif"
      id="a-fifth-animation-wait"
      class="c-fifth-animation c-fifth-animation-wait"
    /> -->
  </div>
  <div>
    <div
      class="c-second-left-container d-flex justify-content-center align-items-center"
    >
      <img class="c-container-title" src="../../../assets/pngs/who_am_i.png" />
    </div>
    <div
      class="c-second-right-container d-flex justify-content-center align-items-center"
    >
      <a href="/about">
        <img
          class="c-container-text"
          src="../../../assets/new_pngs/about_me.png"
        />
      </a>
    </div>
  </div>

  <div class="">
    <div
      class="c-third-left-container d-flex justify-content-center align-items-center"
    >
      <img
        class="c-container-title"
        src="../../../assets/pngs/lets_review.png"
      />
    </div>
    <div
      class="c-third-right-container d-flex justify-content-center align-items-center"
    >
      <a href="/projects">
        <img
          class="c-container-text"
          src="../../../assets/new_pngs/projects.png"
        />
      </a>
    </div>
  </div>

  <div>
    <div
      class="c-fourth-left-container d-flex justify-content-center align-items-center"
    >
      <img
        class="c-container-title"
        src="../../../assets/pngs/lets_see_brands.png"
      />
    </div>
    <div
      class="c-fourth-right-container d-flex justify-content-center align-items-center"
    >
      <a href="/brands">
        <img
          class="c-container-text"
          src="../../../assets/new_pngs/brands.png"
        />
      </a>
    </div>
  </div>

  <div>
    <div
      class="c-fifth-left-container d-flex justify-content-center align-items-center"
    >
      <img
        class="c-container-title"
        src="../../../assets/pngs/need_a_hand.png"
      />
    </div>
    <div
      class="c-fifth-right-container d-flex justify-content-center align-items-center"
    >
      <a href="/contact-me">
        <img
          class="c-container-text"
          src="../../../assets/new_pngs/contact.png"
        />
      </a>
    </div>
  </div>
</div>
