import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MainComponent } from "./views/main/main.component";
import { AboutMeComponent } from "./views/about-me/about-me.component";
import { ProjectsComponent } from "./views/projects/projects.component";
import { NavbarComponent } from "./views/navbar/navbar.component";
import { ProjectDetailsComponent } from "./views/project-details/project-details.component";

import { NgxUsefulSwiperModule } from "ngx-useful-swiper";
import { BrandsComponent } from "./views/brands/brands.component";
import { ContactMeComponent } from "./views/contact-me/contact-me.component";
import { PanelLoginComponent } from "./panel/panel-login/panel-login.component";
import { PanelCategoryComponent } from "./panel/panel-category/panel-category.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { SnackComponent } from "./utils/snack/snack.component";
import { FormsModule } from "@angular/forms";
import { AuthInterceptor } from "./utils/interceptors/auth.interceptor";
import { PanelAboutMeComponent } from "./panel/panel-about-me/panel-about-me.component";
import { QuillModule } from "ngx-quill";
import { FileLoaderComponent } from "./utils/file/file-loader.component";
import { PanelProjectsComponent } from "./panel/panel-projects/panel-projects.component";
import { PanelAddProjectComponent } from "./panel/panel-add-project/panel-add-project.component";
import { PanelMessagesComponent } from "./panel/panel-messages/panel-messages.component";
import { PanelBrandsComponent } from "./panel/panel-brands/panel-brands.component";
import { PanelMessagesDetailComponent } from "./panel/panel-messages-detail/panel-messages-detail.component";
import { ErrorInterceptor } from "./utils/interceptors/error.interceptor";
import { NotFoundComponent } from "./views/not-found/not-found.component";
import { PanelEditProjectComponent } from "./panel/panel-edit-project/panel-edit-project.component";
import { PanelEditCategoryComponent } from "./panel/panel-edit-category/panel-edit-category.component";
import { FileLoaderProjectsComponent } from "./utils/file/file-loader-projects.component";
import { DeviceDetectorModule } from "ngx-device-detector";

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    AboutMeComponent,
    ProjectsComponent,
    NavbarComponent,
    ProjectDetailsComponent,
    BrandsComponent,
    ContactMeComponent,
    PanelLoginComponent,
    PanelCategoryComponent,
    SnackComponent,
    PanelAboutMeComponent,
    FileLoaderComponent,
    PanelProjectsComponent,
    PanelAddProjectComponent,
    PanelMessagesComponent,
    PanelBrandsComponent,
    PanelMessagesDetailComponent,
    NotFoundComponent,
    PanelEditProjectComponent,
    PanelEditCategoryComponent,
    FileLoaderProjectsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxUsefulSwiperModule,
    HttpClientModule,
    FormsModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ["bold", "italic", "underline"],

          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ direction: "ltr" }],

          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],

          ["clean"],

          ["link", "image"],
        ],
      },
    }),
    DeviceDetectorModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
