<app-snack></app-snack>
<div class="c-navbar-container">
  <div
    class="d-flex flex-wrap justify-content-between align-items-center c-navbar-top-container"
  >
    <a
      class="c-navbar-sidenav-button"
      (click)="sideNavCheck == true ? closeSideNav() : openSideNav()"
    >
      <i class="fas fa-bars"></i>
    </a>
    <a href="/"
      ><img class="c-navbar-logo" src="../../../assets/pngs/mylogo.png"
    /></a>
    <button class="btn c-navbar-button" (click)="logoutClicked()">
      Log out
    </button>
  </div>
  <div class="c-navbar-sidenav-container" id="sideNav">
    <a [routerLink]="['/panel/about-me']" class="c-navbar-sidenav-link">
      <i class="fas fa-user"></i>
      About Me</a
    >
    <a
      [routerLink]="['/panel/category']"
      [routerLinkActive]="['c-navbar-active']"
      class="c-navbar-sidenav-link"
    >
      <i class="fas fa-map"></i>
      Categories</a
    >
    <a [routerLink]="['/panel/projects']" class="c-navbar-sidenav-link">
      <i class="fas fa-file"></i>
      Projects
    </a>
    <a [routerLink]="['/panel/brands']" class="c-navbar-sidenav-link">
      <i class="fas fa-image"></i>
      Brands
    </a>
    <a [routerLink]="['/panel/messages']" class="c-navbar-sidenav-link">
      <i class="fas fa-comments"></i>
      Messages
    </a>
  </div>
  <div class="c-content-container" id="navContainer">
    <div class="container-fluid">
      <div class="row c-add-category-container">
        <div class="col-xl-3 col-lg-12 col-md-12">
          <h5>Add Category:</h5>
          <div class="mt-3">
            <input
              type="text"
              class="form-control c-category-input"
              placeholder="Title"
              [(ngModel)]="titleInput"
            />
            <label class="mt-3">Type:</label>
            <select
              class="form-control c-category-input"
              (ngModelChange)="selectChange($event)"
              [ngModel]="selectValue"
            >
              <option [value]="true">Color</option>
              <option [value]="false">Image</option>
            </select>
            <input
              type="text"
              class="form-control c-category-input mt-3"
              placeholder="Color (Hex: #xxxxxx)"
              *ngIf="inputRender == true"
              [(ngModel)]="colorInput"
            />
            <button
              class="btn c-category-button mt-3 d-block mx-auto"
              (click)="fileInput.click()"
              *ngIf="inputRender == false"
              [disabled]="uploadDisabled"
              type="button"
            >
              Upload
            </button>
            <input
              type="file"
              class="d-none"
              #fileInput
              (change)="onFileSelected($event)"
              [disabled]="uploadDisabled"
            />
            <span
              *ngIf="loading"
              class="spinner-border my-3 d-block mx-auto"
            ></span>
            <button
              class="btn c-category-button mt-3 d-block mx-auto"
              (click)="addCategoryClicked()"
              [disabled]="loading"
            >
              Add
            </button>
          </div>
        </div>
        <div class="col-xl-9 col-lg-12 col-md-12 c-category-table-wrap">
          <h5>Categories:</h5>
          <span
            *ngIf="tableLoading"
            class="spinner-border my-3 d-block mx-auto"
          ></span>
          <div class="table-responsive" *ngIf="!tableLoading">
            <table class="table c-category-table">
              <tr>
                <th class="c-category-table-head-left">Category Id</th>
                <th>Title</th>
                <th>Color</th>
                <th>Image</th>
                <th>#</th>
                <th class="c-category-table-head-right">#</th>
              </tr>
              <tr *ngFor="let category of categoriesResult; let i = index">
                <td>{{ category.id }}</td>
                <td>{{ category.title }}</td>
                <td>
                  <div
                    class="c-table-color mx-auto mb-2"
                    *ngIf="category.color != ''"
                    [ngStyle]="{ 'background-color': category.color }"
                  ></div>
                  <p class="mb-0" *ngIf="category.color != ''">
                    {{ category.color }}
                  </p>
                  <p class="mb-0" *ngIf="category.color == ''">N/A</p>
                </td>
                <td>
                  <div
                    *ngIf="category.image_id != ''"
                    class="c-table-image-container d-inline-block"
                  >
                    <file-loader [imageId]="category.image_id"></file-loader>
                  </div>
                  <p class="mb-0" *ngIf="category.image_id == ''">N/A</p>
                </td>
                <td>
                  <button
                    class="btn c-category-remove"
                    (click)="categoryDeleteClicked(i, category.id)"
                    [disabled]="deleteLoading"
                  >
                    Remove
                  </button>
                </td>
                <td>
                  <button
                    class="btn c-category-remove"
                    [routerLink]="['/panel/category/edit', category.id]"
                  >
                    Edit
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
