<app-snack></app-snack>
<div class="c-navbar-container">
  <div
    class="d-flex flex-wrap justify-content-between align-items-center c-navbar-top-container"
  >
    <a
      class="c-navbar-sidenav-button"
      (click)="sideNavCheck == true ? closeSideNav() : openSideNav()"
    >
      <i class="fas fa-bars"></i>
    </a>
    <a href="/"
      ><img class="c-navbar-logo" src="../../../assets/pngs/mylogo.png"
    /></a>
    <button class="btn c-navbar-button" (click)="logoutClicked()">
      Log out
    </button>
  </div>
  <div class="c-navbar-sidenav-container" id="sideNav">
    <a [routerLink]="['/panel/about-me']" class="c-navbar-sidenav-link">
      <i class="fas fa-user"></i>
      About Me</a
    >
    <a [routerLink]="['/panel/category']" class="c-navbar-sidenav-link">
      <i class="fas fa-map"></i>
      Categories</a
    >
    <a [routerLink]="['/panel/projects']" class="c-navbar-sidenav-link">
      <i class="fas fa-file"></i>
      Projects
    </a>
    <a [routerLink]="['/panel/brands']" class="c-navbar-sidenav-link">
      <i class="fas fa-image"></i>
      Brands
    </a>
    <a
      [routerLink]="['/panel/messages']"
      [routerLinkActive]="['c-navbar-active']"
      class="c-navbar-sidenav-link"
    >
      <i class="fas fa-comments"></i>
      Messages
    </a>
  </div>
  <div class="c-content-container" id="navContainer">
    <div class="container-fluid">
      <div class="row c-messages-container">
        <div class="col-12">
          <h5>Messages:</h5>
        </div>
        <div class="col-12">
          <div class="table-responsive">
            <table class="table c-messages-table">
              <tr>
                <th class="c-messages-table-head-left">Date</th>
                <th>Read State</th>
                <th>Email</th>
                <th>Name</th>
                <th class="c-messages-table-head-right">#</th>
              </tr>
              <tr *ngFor="let message of messagesResult">
                <td>{{ message.date }}</td>
                <td>
                  <i *ngIf="message.is_read == '0'" class="fas fa-times"></i>
                  <i *ngIf="message.is_read == '1'" class="fas fa-check"></i>
                </td>
                <td>
                  {{ message.email }}
                </td>
                <td>{{ message.name }}</td>
                <td>
                  <button
                    class="btn c-messages-button"
                    [routerLink]="['../messages', message.id]"
                  >
                    Details
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
