<app-snack></app-snack>
<div class="c-navbar-container">
  <div
    class="d-flex flex-wrap justify-content-between align-items-center c-navbar-top-container"
  >
    <a
      class="c-navbar-sidenav-button"
      (click)="sideNavCheck == true ? closeSideNav() : openSideNav()"
    >
      <i class="fas fa-bars"></i>
    </a>
    <a href="/"
      ><img class="c-navbar-logo" src="../../../assets/pngs/mylogo.png"
    /></a>
    <button class="btn c-navbar-button" (click)="logoutClicked()">
      Log out
    </button>
  </div>
  <div class="c-navbar-sidenav-container" id="sideNav">
    <a [routerLink]="['/panel/about-me']" class="c-navbar-sidenav-link">
      <i class="fas fa-user"></i>
      About Me</a
    >
    <a [routerLink]="['/panel/category']" class="c-navbar-sidenav-link">
      <i class="fas fa-map"></i>
      Categories</a
    >
    <a
      [routerLink]="['/panel/projects']"
      [routerLinkActive]="['c-navbar-active']"
      class="c-navbar-sidenav-link"
    >
      <i class="fas fa-file"></i>
      Projects
    </a>
    <a [routerLink]="['/panel/brands']" class="c-navbar-sidenav-link">
      <i class="fas fa-image"></i>
      Brands
    </a>
    <a [routerLink]="['/panel/messages']" class="c-navbar-sidenav-link">
      <i class="fas fa-comments"></i>
      Messages
    </a>
  </div>
  <div class="c-content-container" id="navContainer">
    <div class="container-fluid">
      <div class="row c-projects-container">
        <div class="col-xl-3 col-lg-12 cold-md-12">
          <h5>Projects:</h5>
          <button
            [routerLink]="['../add-project']"
            class="btn c-projects-button mt-3 d-block mx-auto"
          >
            Add Project
          </button>
        </div>
        <div class="col-xl-9 col-lg-12 col-md-12 c-projects-table-wrap">
          <span
            *ngIf="loading"
            class="spinner-border my-3 d-block mx-auto"
          ></span>
          <div *ngIf="!loading" class="table-responsive">
            <table class="table c-projects-table">
              <tr>
                <th class="c-projects-table-head-left">Title</th>
                <th>Category Id</th>
                <th>Image</th>
                <th>#</th>
                <th class="c-projects-table-head-right">#</th>
              </tr>
              <tr *ngFor="let project of projectsResult; let i = index">
                <td>{{ project.title }}</td>
                <td>{{ project.category_id }}</td>
                <td>
                  <div class="c-table-image-container d-inline-block">
                    <file-loader [imageId]="project.images[0]"></file-loader>
                  </div>
                </td>
                <td>
                  <button
                    class="btn c-projects-button"
                    (click)="removeProjectClicked(i, project.id)"
                    [disabled]="deleteLoading"
                  >
                    Remove
                  </button>
                </td>
                <td>
                  <button
                    class="btn c-projects-button"
                    [routerLink]="['/panel/edit-project', project.id]"
                  >
                    Edit
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
