import { Component, OnInit } from "@angular/core";
import { AlertService } from "src/app/services/alert/alert.service";
import { CategoryService } from "src/app/services/category/category.service";
import { FileService } from "src/app/services/file/file.service";
import { AddCategoryRequest } from "src/app/models/category/add-category-request";
import { flatMap } from "rxjs/operators";
import { CategoriesResponse } from "src/app/models/category/categories-response";
import { Subject } from "rxjs";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: "app-panel-category",
  templateUrl: "./panel-category.component.html",
  styleUrls: ["./panel-category.component.scss"],
})
export class PanelCategoryComponent implements OnInit {
  titleInput: string;
  colorInput: string;
  selectValue: string = "true";
  inputRender = true;
  loading = false;
  uploadResponse: string;
  uploadDisabled = false;
  tableLoading = false;
  categoriesResult: CategoriesResponse[] = [];
  sideNavCheck = false;
  reloadSubject: Subject<any> = new Subject();
  deleteLoading = false;

  constructor(
    private alertService: AlertService,
    private categoryService: CategoryService,
    private fileService: FileService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    document.body.style.backgroundColor = "#f2f2f2";
    this.getCategories();
  }

  getCategories() {
    this.tableLoading = true;
    this.reloadSubject
      .pipe(flatMap(() => this.categoryService.getCategories()))
      .subscribe(
        (res) => {
          this.categoriesResult = res;
          this.tableLoading = false;
        },
        (error) => {
          console.log("category error", error);
          this.alertService.error(error);
          this.tableLoading = false;
        }
      );
    this.reloadSubject.next(1);
  }

  selectChange(event) {
    this.selectValue = event;
    if (event == "true") {
      this.inputRender = true;
    }
    if (event == "false") {
      this.inputRender = false;
    }
  }

  onFileSelected(event) {
    const files = event.target.files;
    if (files) {
      for (let file of files) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
      }
    }
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i], files[i]["name"]);
    }
    this.loading = true;
    this.fileService.upload(formData).subscribe(
      (res) => {
        this.alertService.success("Image uploaded successfully.");
        this.uploadResponse = res.id;
        this.loading = false;
        this.uploadDisabled = true;
      },
      (error) => {
        console.log("upload error", error);
        this.alertService.error(error);
        this.loading = false;
        this.uploadDisabled = false;
      }
    );
  }

  addCategoryClicked() {
    if (this.titleInput == null) {
      this.alertService.error("Please fill title input.");
      return;
    }

    if (this.inputRender == true) {
      if (this.colorInput == null) {
        this.alertService.error("Please fill color input.");
        return;
      }
    }

    if (this.inputRender == false) {
      if (this.uploadResponse == null) {
        this.alertService.error("Please upload an image.");
        return;
      }
    }

    this.loading = true;
    this.categoryService
      .addCategory(
        new AddCategoryRequest(
          this.titleInput,
          this.colorInput,
          this.uploadResponse
        )
      )
      .subscribe(
        (res) => {
          this.alertService.success("Category added successfully.");
          this.loading = false;
          this.reloadSubject.next(1);
        },
        (error) => {
          console.log("submit error", error);
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }

  categoryDeleteClicked(index, categoryId) {
    this.deleteLoading = true;
    this.categoryService.deleteCategory(categoryId).subscribe(
      (res) => {
        this.alertService.success("Category removed.");
        this.deleteLoading = false;
        console.log(index);
        this.categoriesResult.splice(index, 1);
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
        this.deleteLoading = false;
      }
    );
  }

  openSideNav() {
    document.getElementById("sideNav").style.width = "300px";
    document.getElementById("navContainer").style.marginLeft = "300px";
    this.sideNavCheck = true;
  }

  closeSideNav() {
    document.getElementById("sideNav").style.width = "0";
    document.getElementById("navContainer").style.marginLeft = "0";
    this.sideNavCheck = false;
  }

  logoutClicked() {
    this.authService.logout();
  }
}
