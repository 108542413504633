import { Component, OnInit } from "@angular/core";
import { AlertService } from "src/app/services/alert/alert.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-panel-login",
  templateUrl: "./panel-login.component.html",
  styleUrls: ["./panel-login.component.scss"],
})
export class PanelLoginComponent implements OnInit {
  usernameInput: string;
  passwordInput: string;
  loading = false;

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private router: Router
  ) {
    if (this.authService.currentUserValue) {
      this.router.navigate(["/panel/about-me"]);
    }
  }

  ngOnInit(): void {
    document.body.style.backgroundColor = "#f2f2f2";
  }

  loginClicked() {
    if (this.usernameInput == null) {
      this.alertService.error("Invalid username.");
      return;
    }

    if (this.passwordInput == null) {
      this.alertService.error("Invalid password");
      return;
    }

    this.loading = true;
    this.authService.login(this.usernameInput, this.passwordInput).subscribe(
      (res) => {
        this.loading = false;
        this.router.navigate(["/panel/about-me"]);
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
      }
    );
  }
}
