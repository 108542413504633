import { Component, OnInit } from "@angular/core";
import { AlertService } from "src/app/services/alert/alert.service";
import { BrandsService } from "src/app/services/brands/brands.service";
import { FileService } from "src/app/services/file/file.service";
import { Subject } from "rxjs";
import { flatMap } from "rxjs/operators";
import { BrandsResponse } from "src/app/models/brands/brands-response";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: "app-panel-brands",
  templateUrl: "./panel-brands.component.html",
  styleUrls: ["./panel-brands.component.scss"],
})
export class PanelBrandsComponent implements OnInit {
  sideNavCheck = false;
  brandsResult: BrandsResponse[] = [];
  tableLoading = false;
  loading = false;
  deleteLoading = false;
  reloadSubject: Subject<number> = new Subject();

  constructor(
    private alertService: AlertService,
    private brandsService: BrandsService,
    private fileService: FileService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    document.body.style.backgroundColor = "#f2f2f2";

    this.tableLoading = true;
    this.reloadSubject
      .pipe(flatMap(() => this.brandsService.getBrands()))
      .subscribe(
        (res) => {
          this.brandsResult = res;
          this.tableLoading = false;
        },
        (error) => {
          console.log(error);
          this.alertService.error(error);
          this.tableLoading = false;
        }
      );
    this.reloadSubject.next(1);
  }

  onFileSelected(event) {
    const files = event.target.files;
    if (files) {
      for (let file of files) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
      }
    }
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i], files[i]["name"]);
    }
    this.loading = true;
    this.fileService
      .upload(formData)
      .pipe(flatMap((x) => this.brandsService.addBrand(x.id)))
      .subscribe(
        (res) => {
          this.alertService.success("Image uploaded successfully.");
          this.loading = false;
          this.reloadSubject.next(1);
        },
        (error) => {
          console.log("upload error", error);
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }

  deleteBrand(index, brandId) {
    this.deleteLoading = true;
    this.brandsService.deleteBrand(brandId).subscribe(
      (res) => {
        this.deleteLoading = false;
        this.alertService.success("Brand removed.");
        this.brandsResult.splice(index, 1);
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
        this.deleteLoading = false;
      }
    );
  }

  openSideNav() {
    document.getElementById("sideNav").style.width = "300px";
    document.getElementById("navContainer").style.marginLeft = "300px";
    this.sideNavCheck = true;
  }

  closeSideNav() {
    document.getElementById("sideNav").style.width = "0";
    document.getElementById("navContainer").style.marginLeft = "0";
    this.sideNavCheck = false;
  }

  logoutClicked() {
    this.authService.logout();
  }
}
