<app-snack></app-snack>
<div class="container-fluid c-login-container">
  <div class="row c-login-row">
    <div class="col d-flex justify-content-center align-items-center">
      <div class="c-login-wrap d-flex flex-column justify-content-between">
        <a href="/">
          <img
            class="c-login-logo d-block mx-auto"
            src="../../../assets/pngs/mylogo.png"
        /></a>
        <div>
          <div class="input-group mb-3 c-login-input-wrap mx-auto">
            <div class="input-group-prepend">
              <span class="input-group-text c-login-input-icon" id="username">
                <i class="fas fa-user"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control c-login-input"
              placeholder="Username"
              aria-label="Username"
              aria-describedby="username"
              [(ngModel)]="usernameInput"
            />
          </div>
          <div class="input-group mb-3 c-login-input-wrap mx-auto">
            <div class="input-group-prepend">
              <span class="input-group-text c-login-input-icon" id="password">
                <i class="fas fa-key"></i>
              </span>
            </div>
            <input
              type="password"
              class="form-control c-login-input"
              placeholder="Password"
              aria-label="Password"
              aria-describedby="password"
              [(ngModel)]="passwordInput"
            />
          </div>
          <span
            *ngIf="loading"
            class="spinner-border my-3 d-block mx-auto"
          ></span>
          <button
            class="btn c-login-button d-block mx-auto"
            (click)="loginClicked()"
            [disabled]="loading"
          >
            Login
          </button>
        </div>
        <p class="c-login-footer">
          Copyright &copy; 2020, Nastaran Shivaee.All rights reserved.
        </p>
      </div>
    </div>
  </div>
</div>
