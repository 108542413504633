import { Component, OnInit } from "@angular/core";
import { AlertService } from "src/app/services/alert/alert.service";
import { BrandsService } from "src/app/services/brands/brands.service";
import { BrandsResponse } from "src/app/models/brands/brands-response";
import { share, delay } from "rxjs/operators";
import { TweenLite } from "gsap";

@Component({
  selector: "app-brands",
  templateUrl: "./brands.component.html",
  styleUrls: ["./brands.component.scss"],
})
export class BrandsComponent implements OnInit {
  loading = false;
  brandsResult: BrandsResponse[] = [];

  constructor(
    private alertService: AlertService,
    private brandsService: BrandsService
  ) {}

  ngOnInit(): void {
    document.body.style.backgroundColor = "#fff";

    this.loading = true;
    const brands = this.brandsService.getBrands().pipe(share());

    brands.subscribe(
      (res) => {
        this.brandsResult = res;
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
        this.loading = false;
      }
    );

    brands.pipe(delay(500)).subscribe(
      (res) => {
        for (let i = 0; i < res.length; i++) {
          TweenLite.fromTo(
            ".c-brands-" + i,
            0.5,
            {
              opacity: 0,
            },
            {
              opacity: 1,
            }
          ).delay(i * 0.5);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
