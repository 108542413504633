import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AddCategoryRequest } from "src/app/models/category/add-category-request";
import { CategoriesResponse } from "src/app/models/category/categories-response";
import { CategoryProjectsResponse } from "src/app/models/category/category-projects-response";
import { UpdateCategoryRequest } from "src/app/models/category/update-category-request";

@Injectable({
  providedIn: "root",
})
export class CategoryService {
  constructor(private http: HttpClient) {}

  addCategory(addCategoryRequest: AddCategoryRequest): Observable<string> {
    const url = "/api/category";
    const body = addCategoryRequest;
    return this.http.post<string>(url, body);
  }

  getCategories(): Observable<CategoriesResponse[]> {
    const url = "/api/category";
    return this.http.get<CategoriesResponse[]>(url);
  }

  categoryProjects(categoryId: string): Observable<CategoryProjectsResponse[]> {
    const url = "/api/category/" + categoryId;
    return this.http.get<CategoryProjectsResponse[]>(url);
  }

  deleteCategory(categoryId: number): Observable<string> {
    const url = "/api/category/" + categoryId;
    return this.http.delete<string>(url);
  }

  updateCategory(
    categoryId: number,
    updateCategoryReq: UpdateCategoryRequest
  ): Observable<string> {
    const url = "/api/category/" + categoryId;
    const body = updateCategoryReq;
    return this.http.put<string>(url, body);
  }
}
