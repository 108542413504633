<app-navbar></app-navbar>
<app-snack></app-snack>
<div class="container-fluid c-projects-container">
  <div class="row c-projects-row">
    <div class="c-projects-items-container">
      <span *ngIf="loading" class="spinner-border my-3 d-block mx-auto"></span>
      <div
        class="d-flex justify-content-center align-items-center c-projects-item c-projects-{{
          i
        }}"
        *ngFor="let category of categoryResult; let i = index"
        [ngStyle]="{
          'background-color': category.color
        }"
        [routerLink]="['/projects', category.id]"
      >
        <div class="c-projects-img" *ngIf="category.image_id != ''">
          <file-loader-projects
            [imageId]="category.image_id"
          ></file-loader-projects>
        </div>
        <div class="c-projects-overlay"></div>
        <p class="c-projects-item-title">
          {{ category.title }}
        </p>
      </div>
    </div>
  </div>
</div>
