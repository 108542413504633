import { Component, OnInit } from "@angular/core";
import { AlertService } from "src/app/services/alert/alert.service";
import { ProjectService } from "src/app/services/project/project.service";
import { ProjectsResponse } from "src/app/models/project/projects-response";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: "app-panel-projects",
  templateUrl: "./panel-projects.component.html",
  styleUrls: ["./panel-projects.component.scss"],
})
export class PanelProjectsComponent implements OnInit {
  sideNavCheck = false;
  loading = false;
  projectsResult: ProjectsResponse[] = [];
  deleteLoading = false;

  constructor(
    private alertService: AlertService,
    private projectService: ProjectService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    document.body.style.backgroundColor = "#f2f2f2";
    this.getProjects();
  }

  getProjects() {
    this.loading = true;
    this.projectService.getProjects().subscribe(
      (res) => {
        this.projectsResult = res;
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
        this.alertService.error(error);
      }
    );
  }

  removeProjectClicked(index, projectId) {
    this.deleteLoading = true;
    this.projectService.deleteProject(projectId).subscribe(
      (res) => {
        this.projectsResult.splice(index, 1);
        this.alertService.success("Project removed.");
        this.deleteLoading = false;
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
        this.deleteLoading = false;
      }
    );
  }

  openSideNav() {
    document.getElementById("sideNav").style.width = "300px";
    document.getElementById("navContainer").style.marginLeft = "300px";
    this.sideNavCheck = true;
  }

  closeSideNav() {
    document.getElementById("sideNav").style.width = "0";
    document.getElementById("navContainer").style.marginLeft = "0";
    this.sideNavCheck = false;
  }

  logoutClicked() {
    this.authService.logout();
  }
}
