import { Component, OnInit } from "@angular/core";
import { SwiperOptions } from "swiper";
import { ActivatedRoute } from "@angular/router";
import { AlertService } from "src/app/services/alert/alert.service";
import { CategoryService } from "src/app/services/category/category.service";
import { CategoryProjectsResponse } from "src/app/models/category/category-projects-response";
import { TimelineLite } from "gsap";
import { share, delay } from "rxjs/operators";
import { DeviceDetectorService } from "ngx-device-detector";
declare var $: any;

@Component({
  selector: "app-project-details",
  templateUrl: "./project-details.component.html",
  styleUrls: ["./project-details.component.scss"],
})
export class ProjectDetailsComponent implements OnInit {
  projectsResult: CategoryProjectsResponse[] = [];
  projectsLoading = false;
  selectedProject: CategoryProjectsResponse = new CategoryProjectsResponse(
    null,
    [],
    null,
    null,
    null,
    null
  );
  videoSrc: string;

  detailsSlidesConfig: SwiperOptions = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private categoryService: CategoryService,
    private detectorService: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    document.body.style.backgroundColor = "#fff";

    const categoryId = this.activatedRoute.snapshot.paramMap.get("id");

    const projects = this.categoryService
      .categoryProjects(categoryId)
      .pipe(share());

    this.projectsLoading = true;
    projects.subscribe(
      (res) => {
        this.projectsResult = res;
        this.projectsLoading = false;
        if (res.length > 0) {
          this.selectedProject = res[0];
          this.videoSrc =
            "/api/files/" + res[0].video_url + "?access_token=dGVzdA==";
        }
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
        this.projectsLoading = false;
      }
    );
    projects.pipe(delay(500)).subscribe(
      (res) => {
        const timeline = new TimelineLite();

        timeline
          .fromTo(
            ".c-details-title",
            0.3,
            {
              opacity: 0,
            },
            {
              opacity: 1,
            }
          )
          .fromTo(
            ".c-details-link, .c-details-category-item, .c-details-content-title, .c-details-bottom-container",
            0.3,
            { opacity: 0 },
            { opacity: 1 }
          );
      },
      (error) => {
        console.log(error);
      }
    );
  }

  projectClicked(projectId: string) {
    const findedProject = this.projectsResult.find((x) => x.id == projectId);
    this.selectedProject = findedProject;
    this.videoSrc =
      "/api/files/" + findedProject.video_url + "?access_token=dGVzdA==";
    const animation = new TimelineLite();
    animation.fromTo(
      ".c-details-title, .c-details-content-title, .c-details-bottom-container",
      0.3,
      {
        opacity: 0,
      },
      {
        opacity: 1,
      }
    );
  }

  imageHover() {
    if (this.detectorService.isDesktop()) {
      $(".swiper-slide")
        .on("mouseover", function () {
          $(this)
            .children(".swiper-img")
            .css({ transform: "scale(" + $(this).attr("data-scale") + ")" });
        })
        .on("mouseout", function () {
          $(this).children(".swiper-img").css({ transform: "scale(1)" });
        })
        .on("mousemove", function (e) {
          $(this)
            .children(".swiper-img")
            .css({
              "transform-origin":
                ((e.pageX - $(this).offset().left) / $(this).width()) * 100 +
                "% " +
                ((e.pageY - $(this).offset().top) / $(this).height()) * 100 +
                "%",
            });
        });
    } else {
      return;
    }
  }

  onImageMouseover() {
    this.imageHover();
  }
}
