import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { AlertService } from "src/app/services/alert/alert.service";

@Component({
  selector: "app-snack",
  templateUrl: "./snack.component.html",
  styleUrls: ["./snack.component.scss"],
})
export class SnackComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  message: any;

  constructor(private alertService: AlertService) {}

  ngOnInit(): void {
    this.subscription = this.alertService.getAlert().subscribe((message) => {
      switch (message && message.type) {
        case "success":
          message.cssClass = "c-snackbar c-snackbar-show";
          break;
        case "error":
          message.cssClass = "c-snackbar c-snackbar-show";
          break;
        case "information":
          message.cssClass = "c-snackbar c-snackbar-show";
          break;
      }
      this.message = message;
      setTimeout(() => {
        if (this.message != null) {
          this.message.cssClass = "c-snackbar";
        }
      }, 3000);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
