<app-snack></app-snack>
<div class="c-navbar-container">
  <div
    class="d-flex flex-wrap justify-content-between align-items-center c-navbar-top-container"
  >
    <a
      class="c-navbar-sidenav-button"
      (click)="sideNavCheck == true ? closeSideNav() : openSideNav()"
    >
      <i class="fas fa-bars"></i>
    </a>
    <a href="/"
      ><img class="c-navbar-logo" src="../../../assets/pngs/mylogo.png"
    /></a>
    <button class="btn c-navbar-button" (click)="logoutClicked()">
      Log out
    </button>
  </div>
  <div class="c-navbar-sidenav-container" id="sideNav">
    <a [routerLink]="['/panel/about-me']" class="c-navbar-sidenav-link">
      <i class="fas fa-user"></i>
      About Me</a
    >
    <a [routerLink]="['/panel/category']" class="c-navbar-sidenav-link">
      <i class="fas fa-map"></i>
      Categories</a
    >
    <a [routerLink]="['/panel/projects']" class="c-navbar-sidenav-link">
      <i class="fas fa-file"></i>
      Projects
    </a>
    <a [routerLink]="['/panel/brands']" class="c-navbar-sidenav-link">
      <i class="fas fa-image"></i>
      Brands
    </a>
    <a [routerLink]="['/panel/messages']" class="c-navbar-sidenav-link">
      <i class="fas fa-comments"></i>
      Messages
    </a>
  </div>
  <div class="c-content-container" id="navContainer">
    <div class="container-fluid">
      <div class="row c-message-container">
        <div class="col-12 mb-3">
          <h5>Message Details:</h5>
        </div>
        <div class="col-12">
          <p class="c-message-title">Name: {{ messageDetailResult.name }}</p>
          <p class="c-message-title">Email: {{ messageDetailResult.email }}</p>
          <p class="c-message-title">Date: {{ messageDetailResult.date }}</p>
          <div class="c-message-text-wrap">
            <h5>Message:</h5>
            <p>
              {{ messageDetailResult.message }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
