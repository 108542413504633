import { Component, Input } from "@angular/core";
import { FileService } from "src/app/services/file/file.service";

@Component({
  selector: "file-loader",
  template: ` <img [attr.src]="image" /> `,
  styles: ["img {width: 100%; height: 100%}"],
})
export class FileLoaderComponent {
  private _imageId: string;
  image: any;

  @Input()
  set imageId(imageId: string) {
    this._imageId = imageId;
    if (imageId == null) {
      return;
    }
    this.fileService.loadFile(this._imageId).subscribe(
      (x) => {
        this.createImageFromBlob(x.image);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  constructor(private fileService: FileService) {}

  createImageFromBlob(img: Blob) {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      this.image = reader.result;
    });
    if (img) {
      reader.readAsDataURL(img);
    }
  }
}
