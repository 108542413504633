import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AddProjectRequest } from "src/app/models/project/add-project-request";
import { Observable } from "rxjs";
import { ProjectsResponse } from "src/app/models/project/projects-response";
import { UpdateProjectRequest } from "src/app/models/project/update-project-request";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  constructor(private http: HttpClient) {}

  addProject(addProjectRequest: AddProjectRequest): Observable<string> {
    const url = "/api/projects";
    const body = addProjectRequest;
    return this.http.post<string>(url, body);
  }

  getProjects(): Observable<ProjectsResponse[]> {
    const url = "/api/projects";
    return this.http.get<ProjectsResponse[]>(url);
  }

  deleteProject(projectId: string): Observable<string> {
    const url = "/api/projects/" + projectId;
    return this.http.delete<string>(url);
  }

  updateProject(
    projectId: string,
    updateProjectReq: UpdateProjectRequest
  ): Observable<string> {
    const url = "/api/projects/" + projectId;
    const body = updateProjectReq;
    return this.http.put<string>(url, body);
  }
}
