import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { BrandsResponse } from "src/app/models/brands/brands-response";

@Injectable({
  providedIn: "root",
})
export class BrandsService {
  constructor(private http: HttpClient) {}

  addBrand(image_id: string): Observable<string> {
    const url = "/api/brands";
    return this.http.post<string>(url, { image_id });
  }

  getBrands(): Observable<BrandsResponse[]> {
    const url = "/api/brands";
    return this.http.get<BrandsResponse[]>(url);
  }

  deleteBrand(brandId: number): Observable<string> {
    const url = "/api/brands/" + brandId;
    return this.http.delete<string>(url);
  }
}
