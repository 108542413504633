import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AlertService } from "src/app/services/alert/alert.service";
import { MessagesService } from "src/app/services/messages/messages.service";
import { MessagesResponse } from "src/app/models/messages/messages-response";
import { share, filter, flatMap } from "rxjs/operators";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: "app-panel-messages-detail",
  templateUrl: "./panel-messages-detail.component.html",
  styleUrls: ["./panel-messages-detail.component.scss"],
})
export class PanelMessagesDetailComponent implements OnInit {
  sideNavCheck = false;
  messageDetailResult: MessagesResponse;
  loading = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private messagesService: MessagesService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    document.body.style.backgroundColor = "#f2f2f2";

    const messageId = this.activatedRoute.snapshot.paramMap.get("messageId");

    this.loading = true;
    this.messageDetailResult = new MessagesResponse(
      null,
      null,
      null,
      null,
      null,
      null
    );

    const messageDetails = this.messagesService
      .messageDetails(messageId)
      .pipe(share());

    messageDetails.subscribe(
      (res) => {
        this.messageDetailResult = res;
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
        this.loading = false;
      }
    );

    messageDetails
      .pipe(
        filter(() => this.messageDetailResult.is_read == "0"),
        flatMap(() => this.messagesService.updateMessage(messageId, "1"))
      )
      .subscribe(
        (res) => {},
        (error) => {
          console.log(error);
          this.alertService.error(error);
        }
      );
  }

  openSideNav() {
    document.getElementById("sideNav").style.width = "300px";
    document.getElementById("navContainer").style.marginLeft = "300px";
    this.sideNavCheck = true;
  }

  closeSideNav() {
    document.getElementById("sideNav").style.width = "0";
    document.getElementById("navContainer").style.marginLeft = "0";
    this.sideNavCheck = false;
  }

  logoutClicked() {
    this.authService.logout();
  }
}
