import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CategoryService } from "src/app/services/category/category.service";
import { CategoriesResponse } from "src/app/models/category/categories-response";
import { AlertService } from "src/app/services/alert/alert.service";
import { FileService } from "src/app/services/file/file.service";
import { UpdateCategoryRequest } from "src/app/models/category/update-category-request";

@Component({
  selector: "app-panel-edit-category",
  templateUrl: "./panel-edit-category.component.html",
  styleUrls: ["./panel-edit-category.component.scss"],
})
export class PanelEditCategoryComponent implements OnInit {
  sideNavCheck = false;
  paramId: number;
  categoryDetailResult: CategoriesResponse = new CategoriesResponse(
    null,
    null,
    null,
    null
  );
  loading = false;
  titleInput: string;
  colorInput: string;
  selectValue: string = "true";
  inputRender = true;
  uploadResponse: string;
  uploadDisabled = false;
  uploadLoading = false;
  updateLoading = false;
  deleteImageDisabled = false;

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private categoryService: CategoryService,
    private alertService: AlertService,
    private fileService: FileService,
    private router: Router
  ) {}

  ngOnInit(): void {
    document.body.style.backgroundColor = "#f2f2f2";

    const param = this.activatedRoute.snapshot.paramMap.get("catId");
    this.paramId = parseInt(param);

    this.getCategoryDetails(this.paramId);
  }

  getCategoryDetails(catId: number) {
    this.loading = true;
    const details = this.categoryService.getCategories();

    details.subscribe(
      (res) => {
        this.categoryDetailResult = res.find((x) => x.id == catId);
        this.titleInput = this.categoryDetailResult.title;
        this.colorInput = this.categoryDetailResult.color;
        this.uploadResponse = this.categoryDetailResult.image_id;
        if (this.uploadResponse != "") {
          this.deleteImageDisabled = false;
        } else {
          this.deleteImageDisabled = true;
        }
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
        this.loading = false;
      }
    );
  }

  onFileSelected(event) {
    const files = event.target.files;
    if (files) {
      for (let file of files) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
      }
    }
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i], files[i]["name"]);
    }
    this.uploadLoading = true;
    this.fileService.upload(formData).subscribe(
      (res) => {
        this.alertService.success("Image uploaded successfully.");
        this.uploadResponse = res.id;
        this.uploadLoading = false;
        this.uploadDisabled = true;
      },
      (error) => {
        console.log("upload error", error);
        this.alertService.error(error);
        this.uploadLoading = false;
        this.uploadDisabled = false;
      }
    );
  }

  selectChange(event) {
    this.selectValue = event;
    if (event == "true") {
      this.inputRender = true;
    }
    if (event == "false") {
      this.inputRender = false;
    }
  }

  deleteImageClicked() {
    this.deleteImageDisabled = true;
    this.uploadResponse = "";
  }

  editCategoryClicked() {
    if (this.uploadResponse != "" && this.colorInput != "") {
      this.alertService.error(
        "You cannot upload image and add color at the same time."
      );
      return;
    }

    if (this.uploadResponse == "" && this.colorInput == "") {
      this.alertService.error("You must upload an image or add a color.");
      return;
    }

    if (this.titleInput == null) {
      this.alertService.error("Please fill title input.");
      return;
    }

    if (this.inputRender == true) {
      if (this.colorInput == null) {
        this.alertService.error("Please fill color input.");
        return;
      }
    }

    if (this.inputRender == false) {
      if (this.uploadResponse == null) {
        this.alertService.error("Please upload an image.");
        return;
      }
    }

    this.updateLoading = true;
    this.categoryService
      .updateCategory(
        this.paramId,
        new UpdateCategoryRequest(
          this.titleInput,
          this.colorInput,
          this.uploadResponse
        )
      )
      .subscribe(
        (res) => {
          this.updateLoading = false;
          this.alertService.success("Category Successfully Updated.");
          this.router.navigate(["/panel/category"]);
        },
        (error) => {
          console.log(error);
          this.updateLoading = false;
          this.alertService.error(error);
        }
      );
  }

  openSideNav() {
    document.getElementById("sideNav").style.width = "300px";
    document.getElementById("navContainer").style.marginLeft = "300px";
    this.sideNavCheck = true;
  }

  closeSideNav() {
    document.getElementById("sideNav").style.width = "0";
    document.getElementById("navContainer").style.marginLeft = "0";
    this.sideNavCheck = false;
  }

  logoutClicked() {
    this.authService.logout();
  }
}
