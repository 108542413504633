<app-snack></app-snack>
<div class="c-navbar-container">
  <div
    class="d-flex flex-wrap justify-content-between align-items-center c-navbar-top-container"
  >
    <a
      class="c-navbar-sidenav-button"
      (click)="sideNavCheck == true ? closeSideNav() : openSideNav()"
    >
      <i class="fas fa-bars"></i>
    </a>
    <a href="/"
      ><img class="c-navbar-logo" src="../../../assets/pngs/mylogo.png"
    /></a>
    <button class="btn c-navbar-button" (click)="logoutClicked()">
      Log out
    </button>
  </div>
  <div class="c-navbar-sidenav-container" id="sideNav">
    <a
      [routerLink]="['/panel/about-me']"
      [routerLinkActive]="['c-navbar-active']"
      class="c-navbar-sidenav-link"
    >
      <i class="fas fa-user"></i>
      About Me</a
    >
    <a [routerLink]="['/panel/category']" class="c-navbar-sidenav-link">
      <i class="fas fa-map"></i>
      Categories</a
    >
    <a [routerLink]="['/panel/projects']" class="c-navbar-sidenav-link">
      <i class="fas fa-file"></i>
      Projects
    </a>
    <a [routerLink]="['/panel/brands']" class="c-navbar-sidenav-link">
      <i class="fas fa-image"></i>
      Brands
    </a>
    <a [routerLink]="['/panel/messages']" class="c-navbar-sidenav-link">
      <i class="fas fa-comments"></i>
      Messages
    </a>
  </div>
  <div class="c-content-container" id="navContainer">
    <div class="container-fluid">
      <div class="row c-about-me-container">
        <div class="col-xl-3 col-lg-12 col-md-12">
          <h5>Image:</h5>
          <div class="c-about-border">
            <p>Current Image:</p>
            <div class="c-about-image">
              <file-loader [imageId]="uploadResponse"></file-loader>
            </div>
          </div>
          <div class="c-about-border">
            <p>Change Image:</p>
            <span
              *ngIf="uploadLoading"
              class="spinner-border my-3 d-block mx-auto"
            ></span>
            <button
              class="btn c-about-button d-block mx-auto"
              (click)="fileInput.click()"
              [disabled]="uploadLoading"
            >
              Upload
            </button>
            <input
              class="d-none"
              type="file"
              (change)="onFileSelected($event)"
              #fileInput
            />
          </div>
          <div>
            <p>Contact Me Details:</p>
            <input
              type="text"
              class="form-control c-about-input"
              [(ngModel)]="emailInput"
              placeholder="Email"
            />
            <input
              type="text"
              class="form-control c-about-input mt-3"
              [(ngModel)]="phoneInput"
              placeholder="Mobile Number"
            />
          </div>
        </div>
        <div class="col-xl-9 col-lg-12 col-md-12 c-about-wrap">
          <h5>Text:</h5>
          <quill-editor theme="snow" [(ngModel)]="aboutText"> </quill-editor>
        </div>
        <div class="col-12">
          <span
            *ngIf="loading"
            class="spinner-border my-3 d-block mx-auto"
          ></span>
          <button
            [disabled]="loading"
            class="btn c-about-button d-block mx-auto mt-3"
            (click)="updateAboutClicked()"
          >
            Change
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
