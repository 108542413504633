<app-navbar></app-navbar>
<app-snack></app-snack>
<div class="container-fluid c-brands-container">
  <div class="row c-brands-row">
    <div class="col c-brands-items-container">
      <span *ngIf="loading" class="spinner-border my-3 d-block mx-auto"></span>
      <div class="c-brands d-flex flex-wrap align-items-center">
        <div
          class="c-brands-wrap"
          *ngFor="let brand of brandsResult; let i = index"
        >
          <div class="c-brands-img c-brands-{{ i }}">
            <file-loader [imageId]="brand.image_id"></file-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
