import { Component, OnInit, HostListener } from "@angular/core";
import * as ScrollMagic from "scrollmagic";
import "imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap";
import "imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";
import { TimelineMax, TimelineLite, SteppedEase, TweenLite } from "gsap";
import "imports-loader?define=>false!gsap/ScrollToPlugin";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {
  scrollOffset = [];
  section = 0;

  constructor() {}

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    const position =
      (document.documentElement.scrollTop || document.body.scrollTop) +
      document.documentElement.offsetHeight;
    const bottom = document.documentElement.scrollHeight;
    if (position == bottom) {
      document.getElementById("a-arrow-down").style.display = "none";
      document.getElementById("a-arrow-up").style.display = "block";
    } else {
      document.getElementById("a-arrow-down").style.display = "block";
      document.getElementById("a-arrow-up").style.display = "none";
    }
  }

  ngOnInit(): void {
    document.body.style.backgroundColor = "#9fccdd";

    const arrowTl = new TimelineMax();
    const controller = new ScrollMagic.Controller();
    const firstSectionTl = new TimelineLite();
    const nameTl = new TimelineLite();
    const eggTl = new TimelineLite();
    const secondSectionTl = new TimelineLite();

    // First Animations
    setTimeout(() => {
      nameTl.to(".c-first-animation-name", 1, {
        opacity: 0,
      });
      TweenLite.to(".c-navbar-logo", 1, {
        opacity: 1,
      }).delay(1);
    }, 2574);
    eggTl
      .to(".c-first-animation-start", 3, {
        display: "block",
        onComplete: () => {
          const name = document.getElementById("a-first-animation-name");
          name.remove();
        },
      })
      .delay(3.5);
    setTimeout(() => {
      const firstAnim = document.getElementById("a-first-animation-start");
      firstAnim.remove();
      const firstAnimWait = (document.getElementById(
        "a-first-animation-wait"
      ).style.display = "block");
    }, 10700);
    firstSectionTl.to(".c-first-animation-name", 1, {
      opacity: 0,
    });
    firstSectionTl.to(".c-first-animation", 1, {
      opacity: 0,
    });

    // Arrow
    arrowTl.to(".c-down-arrow", 1.2, {
      bottom: 40,
      repeat: -1,
    });

    arrowTl.to(".c-up-arrow", 1.2, {
      bottom: 80,
      repeat: -1,
    });

    // Second Section Timeline
    if (window.innerWidth <= 768) {
      secondSectionTl.to(
        ".c-second-left-container, .c-second-right-container",
        1,
        {
          top: 0,
        }
      );
      secondSectionTl.to(
        ".c-second-middle-container",
        1,
        {
          bottom: 0,
        },
        0
      );
      secondSectionTl.to(".c-mob-second", 1, {
        opacity: 1,
      });
      const secondAnimEase = new SteppedEase(64);
      secondSectionTl.to(".c-mob-second", 30, {
        backgroundPosition: "-16000px",
        ease: secondAnimEase,
      });
      secondSectionTl.to(".c-mob-second", 0.1, {
        opacity: 0,
      });

      secondSectionTl.to(".c-third-left-container", 1, {
        bottom: 0,
      });
      secondSectionTl.to(".c-third-right-container", 1, {
        bottom: 0,
      });
      secondSectionTl.to(".c-mob-third", 1, {
        opacity: 1,
      });
      const thirdAnimEase = new SteppedEase(62);
      secondSectionTl.to(".c-mob-third", 30, {
        backgroundPosition: "-15500px",
        ease: thirdAnimEase,
      });
      secondSectionTl.to(".c-mob-third", 0.1, {
        opacity: 0,
      });

      secondSectionTl.to(".c-fourth-left-container", 1, {
        top: 0,
      });
      secondSectionTl.to(".c-fourth-right-container", 1, {
        top: 0,
      });
      secondSectionTl.to(".c-mob-fourth", 1, {
        opacity: 1,
      });
      const fourthAnimEase = new SteppedEase(59);
      secondSectionTl.to(".c-mob-fourth", 30, {
        backgroundPosition: "-14160px",
        ease: fourthAnimEase,
      });
      secondSectionTl.to(".c-mob-fourth", 0.1, {
        opacity: 0,
      });

      secondSectionTl.to(
        ".c-fifth-left-container, .c-fifth-right-container",
        1,
        {
          top: 0,
        }
      );
      secondSectionTl.to(".c-mob-fifth", 1, {
        opacity: 1,
      });
      const fifthAnimEase = new SteppedEase(67);
      secondSectionTl.to(".c-mob-fifth", 30, {
        backgroundPosition: "-16013px",
        ease: fifthAnimEase,
      });
      secondSectionTl.to(".c-mob-end", 0, {
        display: "block",
      });
    } else {
      secondSectionTl.to(
        ".c-second-left-container, .c-second-right-container",
        1,
        {
          top: 0,
        }
      );
      secondSectionTl.to(
        ".c-second-middle-container",
        1,
        {
          bottom: 0,
        },
        0
      );
      secondSectionTl.to(".c-second-animation", 1, {
        opacity: 1,
      });
      const secondAnimEase = new SteppedEase(64);
      secondSectionTl.to(".c-second-animation", 30, {
        backgroundPosition: "-32000px",
        ease: secondAnimEase,
      });
      secondSectionTl.to(".c-second-animation", 0.1, {
        opacity: 0,
      });

      secondSectionTl.to(".c-third-left-container", 1, {
        bottom: 0,
      });
      secondSectionTl.to(".c-third-right-container", 1, {
        bottom: 0,
      });
      secondSectionTl.to(".c-third-animation", 1, {
        opacity: 1,
      });
      const thirdAnimEase = new SteppedEase(62);
      secondSectionTl.to(".c-third-animation", 30, {
        backgroundPosition: "-31000px",
        ease: thirdAnimEase,
      });
      secondSectionTl.to(".c-third-animation", 0.1, {
        opacity: 0,
      });

      secondSectionTl.to(".c-fourth-left-container", 1, {
        top: 0,
      });
      secondSectionTl.to(".c-fourth-right-container", 1, {
        top: 0,
      });
      secondSectionTl.to(".c-fourth-animation", 1, {
        opacity: 1,
      });
      const fourthAnimEase = new SteppedEase(60);
      secondSectionTl.to(".c-fourth-animation", 30, {
        backgroundPosition: "-29940px",
        ease: fourthAnimEase,
      });
      secondSectionTl.to(".c-fourth-animation", 0.1, {
        opacity: 0,
      });

      secondSectionTl.to(
        ".c-fifth-left-container, .c-fifth-right-container",
        1,
        {
          top: 0,
        }
      );
      secondSectionTl.to(".c-fifth-animation", 1, {
        opacity: 1,
      });
      const fifthAnimEase = new SteppedEase(65);
      secondSectionTl.to(".c-fifth-animation", 30, {
        backgroundPosition: "-32695px",
        ease: fifthAnimEase,
      });
      secondSectionTl.to(".c-animation-end", 0, {
        display: "block",
      });
    }

    // Scenes
    new ScrollMagic.Scene({
      triggerElement: ".c-first-section",
      duration: 200,
      triggerHook: 0,
    })
      .setPin(".c-first-section")
      .setTween(firstSectionTl)
      .addTo(controller);
    new ScrollMagic.Scene({
      triggerElement: ".c-second-section",
      duration: "400%",
      triggerHook: "onLeave",
    })
      .setPin(".c-second-section")
      .setTween(secondSectionTl)
      .addTo(controller);
  }

  scrollToTop() {
    // TweenLite.to(window, 10, { scrollTo: 0 });
    window.scroll(0, 0);
  }

  navbarOpener() {
    document.getElementById("navbarOverlay").style.height = "100%";
    document.body.style.overflowY = "hidden";
  }

  navbarCloser() {
    document.getElementById("navbarOverlay").style.height = "0";
    document.body.style.overflowY = "scroll";
  }
}
