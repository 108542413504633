import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { AboutResponse } from "src/app/models/about/about-response";
import { UpdateAboutRequest } from "src/app/models/about/update-about-request";

@Injectable({
  providedIn: "root",
})
export class AboutService {
  constructor(private http: HttpClient) {}

  updateAbout(changeAboutRequest: UpdateAboutRequest): Observable<string> {
    const url = "/api/about";
    const body = changeAboutRequest;
    return this.http.post<string>(url, body);
  }

  getAbout(): Observable<AboutResponse> {
    const url = "/api/about";
    return this.http.get<AboutResponse>(url);
  }
}
