export class AddProjectRequest {
  images: string[];
  title: string;
  text: string;
  video_url: string;
  category_id: number;

  constructor(
    images: string[],
    title: string,
    text: string,
    video_url: string,
    category_id: number
  ) {
    this.images = images;
    this.title = title;
    this.text = text;
    this.video_url = video_url;
    this.category_id = category_id;
  }
}
