export class UpdateCategoryRequest {
  title: string;
  color: string;
  image_id: string;

  constructor(title: string, color: string, image_id: string) {
    this.title = title;
    this.color = color;
    this.image_id = image_id;
  }
}
