<app-navbar></app-navbar>
<app-snack></app-snack>
<div class="container-fluid c-contact-container">
  <div class="row c-contact-row">
    <div class="col c-contact-items-container d-flex flex-column">
      <div class="table-responsive c-desktop-content">
        <table class="table c-contact-table">
          <tr>
            <th class="c-contact-title">mobile</th>
            <th class="text-center">
              <img
                class="c-contact-logo"
                src="../../../assets/pngs/my_character.png"
              />
            </th>
            <th class="c-contact-title text-right">email</th>
          </tr>
          <tr>
            <td class="c-contact-sub-title">{{ mobileNumber }}</td>
            <td class="text-center pt-5">
              <label class="mt-2 c-contact-sub-title">your name</label>
              <input
                class="form-control c-contact-input mx-auto"
                type="text"
                [(ngModel)]="nameInput"
              />
              <label class="mt-3 c-contact-sub-title">your email</label>
              <input
                class="form-control c-contact-input mx-auto"
                type="text"
                [(ngModel)]="emailInput"
              />
              <label class="mt-3 c-contact-sub-title">your message</label>
              <textarea
                class="form-control c-contact-input mx-auto"
                cols="30"
                rows="5"
                [(ngModel)]="messageInput"
              ></textarea>
              <span
                *ngIf="loading"
                class="spinner-border my-3 d-block mx-auto"
              ></span>
              <button
                class="btn c-contact-button mt-3"
                (click)="messageSubmitClicked()"
                [disabled]="loading || submitDisabled"
              >
                submit
              </button>
            </td>
            <td class="c-contact-sub-title text-right">
              <a class="c-contact-email-link" href="mailto:{{ email }}">{{
                email
              }}</a>
            </td>
          </tr>
        </table>
      </div>
      <div class="c-mobile-content">
        <div class="c-mobile-wrap">
          <img
            class="c-contact-logo d-block mx-auto mb-2"
            src="../../../assets/pngs/my_character.png"
          />
          <p class="c-contact-title mb-0">mobile</p>
          <p class="c-contact-sub-title">{{mobileNumber}}</p>
          <p class="c-contact-title mb-0">email</p>
          <a class="c-contact-email-link" href="mailto:{{ email }}"
            >{{
              email
            }}</a
          >
        </div>
        <div class="text-center">
          <label class="mt-2 c-contact-sub-title">your name</label>
          <input
            class="form-control c-contact-input mx-auto"
            type="text"
            [(ngModel)]="nameInput"
          />
          <label class="mt-3 c-contact-sub-title">your email</label>
          <input
            class="form-control c-contact-input mx-auto"
            type="text"
            [(ngModel)]="emailInput"
          />
          <label class="mt-3 c-contact-sub-title">your message</label>
          <textarea
            class="form-control c-contact-input mx-auto"
            cols="30"
            rows="5"
            [(ngModel)]="messageInput"
          ></textarea>
          <span
            *ngIf="loading"
            class="spinner-border my-3 d-block mx-auto"
          ></span>
          <button
            class="btn c-contact-button mt-3"
            (click)="messageSubmitClicked()"
            [disabled]="loading || submitDisabled"
          >
            submit
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
