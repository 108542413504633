<app-navbar></app-navbar>
<app-snack></app-snack>
<div class="container-fluid c-about-container">
  <div class="row c-about-row">
    <div class="col-12">
      <span *ngIf="loading" class="spinner-border my-3 d-block mx-auto"></span>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4">
      <div class="c-about-img-container">
        <div class="c-about-img" *ngIf="!loading">
          <file-loader [imageId]="aboutImg"></file-loader>
        </div>
      </div>
    </div>
    <div class="col-xl-8 col-lg-8 col-md-8 c-about-text-container">
      <quill-view-html [content]="aboutText" theme="snow"></quill-view-html>
    </div>
  </div>
</div>
