import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { fadeAnimation } from "./fade-animation";
// import * as $ from "jquery";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [fadeAnimation],
})
export class AppComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scroll(0, 0);
    });
    // this.scrollSpeed();
  }

  // scrollSpeed() {
  //   window.addEventListener("DOMMouseScroll", handleScroll);
  //   window.addEventListener("mousewheel", handleScroll);

  //   function wheelDistance(e) {
  //     if (!e) {
  //       e = window.event;
  //     }
  //     var w = e.wheelDelta,
  //       d = e.detail;
  //     if (d) {
  //       return -d / 3; // Firefox;
  //     }

  //     // IE, Safari, Chrome & other browsers
  //     return w / 120;
  //   }

  //   function handleScroll(e) {
  //     var delta = wheelDistance(e);
  //     var time = 700;
  //     var distance = 150;

  //     $("html, body")
  //       .stop()
  //       .animate(
  //         {
  //           scrollTop: $(window).scrollTop() - distance * delta,
  //         },
  //         time
  //       );
  //   }
  // }
}
