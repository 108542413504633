import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  navbarOpener() {
    document.getElementById("navbarOverlay").style.height = "100%";
    document.body.style.overflowY = "hidden";
  }

  navbarCloser() {
    document.getElementById("navbarOverlay").style.height = "0";
    document.body.style.overflowY = "scroll";
  }
}
