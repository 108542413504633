<div class="container-fluid">
  <div class="row">
    <div
      class="col-12 d-flex flex-column justify-content-center align-items-center c-not-found-container"
    >
      <p class="c-not-found-title">404</p>
      <img
        class="c-not-found-img"
        src="../../../assets/pngs/notfoundchar.png"
      />
      <p class="c-not-found-subtitle">Page Not Found :(</p>
    </div>
  </div>
</div>
