import { Component, OnInit } from "@angular/core";
import { AboutService } from "src/app/services/about/about.service";
import { FileService } from "src/app/services/file/file.service";
import { AlertService } from "src/app/services/alert/alert.service";
import { UpdateAboutRequest } from "src/app/models/about/update-about-request";
import { AuthService } from "src/app/services/auth/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-panel-about-me",
  templateUrl: "./panel-about-me.component.html",
  styleUrls: ["./panel-about-me.component.scss"],
})
export class PanelAboutMeComponent implements OnInit {
  uploadResponse: string;
  uploadLoading = false;
  loading = false;
  aboutText: string;
  emailInput: string;
  phoneInput: string;
  sideNavCheck = false;

  constructor(
    private alertService: AlertService,
    private aboutService: AboutService,
    private fileService: FileService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    document.body.style.backgroundColor = "#f2f2f2";
    this.getValues();
  }

  getValues() {
    this.loading = true;
    this.aboutService.getAbout().subscribe(
      (res) => {
        this.aboutText = res.text;
        this.uploadResponse = res.image_id;
        this.emailInput = res.email;
        this.phoneInput = res.phone;
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
        this.loading = false;
      }
    );
  }

  onFileSelected(event) {
    const files = event.target.files;
    if (files) {
      for (const file of files) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
      }
    }
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i], files[i]["name"]);
    }
    this.uploadLoading = true;
    this.fileService.upload(formData).subscribe(
      (res) => {
        this.alertService.success("Image uploaded successfully.");
        this.uploadResponse = res.id;
        this.uploadLoading = false;
      },
      (error) => {
        console.log("upload error", error);
        this.alertService.error(error);
        this.uploadLoading = false;
      }
    );
  }

  updateAboutClicked() {
    if (this.uploadResponse == null || this.uploadResponse == "") {
      this.alertService.error("Please upload an image.");
      return;
    }

    if (this.aboutText == null || this.aboutText == "") {
      this.alertService.error("Please fill text input.");
      return;
    }

    if (this.emailInput == null || this.emailInput == "") {
      this.alertService.error("Please fill email input.");
      return;
    }

    if (this.phoneInput == null || this.phoneInput == "") {
      this.alertService.error("Please fill phone input.");
      return;
    }

    this.loading = true;
    this.aboutService
      .updateAbout(
        new UpdateAboutRequest(
          this.aboutText,
          this.uploadResponse,
          this.emailInput,
          this.phoneInput
        )
      )
      .subscribe(
        (res) => {
          this.alertService.success("About me updated successfully.");
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }

  openSideNav() {
    document.getElementById("sideNav").style.width = "300px";
    document.getElementById("navContainer").style.marginLeft = "300px";
    this.sideNavCheck = true;
  }

  closeSideNav() {
    document.getElementById("sideNav").style.width = "0";
    document.getElementById("navContainer").style.marginLeft = "0";
    this.sideNavCheck = false;
  }

  logoutClicked() {
    this.authService.logout();
  }
}
