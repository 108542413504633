import { Component, OnInit } from "@angular/core";
import { AlertService } from "src/app/services/alert/alert.service";
import { AboutService } from "src/app/services/about/about.service";
import { AboutResponse } from "src/app/models/about/about-response";
import { TweenLite } from "gsap";

@Component({
  selector: "app-about-me",
  templateUrl: "./about-me.component.html",
  styleUrls: ["./about-me.component.scss"],
})
export class AboutMeComponent implements OnInit {
  aboutText: string = null;
  aboutImg: any = null;
  loading = false;

  constructor(
    private alertService: AlertService,
    private aboutService: AboutService
  ) {}

  ngOnInit(): void {
    document.body.style.backgroundColor = "#fff";

    this.loading = true;
    this.aboutService.getAbout().subscribe(
      (res) => {
        this.aboutText = res.text;
        this.aboutImg = res.image_id;
        this.loading = false;
        this.animateItems();
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
        this.loading = false;
      }
    );
  }

  animateItems() {
    TweenLite.fromTo(
      ".c-about-img-container, .c-about-text-container",
      0.7,
      { top: -100, opacity: 0 },
      { top: 0, opacity: 1 }
    );
  }
}
