import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { AuthService } from "src/app/services/auth/auth.service";
import { Router } from "@angular/router";
import { Observable, throwError, empty } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        console.log(err);
        if (err.status == 401) {
          this.authService.logout();
          this.router.navigate(["../panel/login"]);
        }
        let error = err;
        if (err.status == 422) {
          error = err.error.message || err.statusText;
        }
        return empty();
      })
    );
  }
}
