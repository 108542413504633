import { Component, OnInit } from "@angular/core";
import { AlertService } from "src/app/services/alert/alert.service";
import { CategoryService } from "src/app/services/category/category.service";
import { CategoriesResponse } from "src/app/models/category/categories-response";
import { TweenLite } from "gsap";
import { share, delay } from "rxjs/operators";

@Component({
  selector: "app-projects",
  templateUrl: "./projects.component.html",
  styleUrls: ["./projects.component.scss"],
})
export class ProjectsComponent implements OnInit {
  categoryResult: CategoriesResponse[] = [];
  loading = false;

  constructor(
    private alertService: AlertService,
    private categoryService: CategoryService
  ) {}

  ngOnInit(): void {
    document.body.style.backgroundColor = "#fff";

    this.loading = true;
    const categories = this.categoryService.getCategories().pipe(share());
    categories.subscribe(
      (res) => {
        this.categoryResult = res;
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
        this.loading = false;
      }
    );
    categories.pipe(delay(500)).subscribe(
      (res) => {
        for (let i = 0; i < res.length; i++) {
          TweenLite.fromTo(
            ".c-projects-" + i,
            0.5,
            {
              right: -100,
              opacity: 0,
            },
            {
              right: 0,
              opacity: 1,
            }
          ).delay(i * 0.1);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
