<app-snack></app-snack>
<div class="c-navbar-container">
  <div
    class="d-flex flex-wrap justify-content-between align-items-center c-navbar-top-container"
  >
    <a
      class="c-navbar-sidenav-button"
      (click)="sideNavCheck == true ? closeSideNav() : openSideNav()"
    >
      <i class="fas fa-bars"></i>
    </a>
    <a href="/"
      ><img class="c-navbar-logo" src="../../../assets/pngs/mylogo.png"
    /></a>
    <button class="btn c-navbar-button" (click)="logoutClicked()">
      Log out
    </button>
  </div>
  <div class="c-navbar-sidenav-container" id="sideNav">
    <a [routerLink]="['/panel/about-me']" class="c-navbar-sidenav-link">
      <i class="fas fa-user"></i>
      About Me</a
    >
    <a [routerLink]="['/panel/category']" class="c-navbar-sidenav-link">
      <i class="fas fa-map"></i>
      Categories</a
    >
    <a [routerLink]="['/panel/projects']" class="c-navbar-sidenav-link">
      <i class="fas fa-file"></i>
      Projects
    </a>
    <a [routerLink]="['/panel/brands']" class="c-navbar-sidenav-link">
      <i class="fas fa-image"></i>
      Brands
    </a>
    <a [routerLink]="['/panel/messages']" class="c-navbar-sidenav-link">
      <i class="fas fa-comments"></i>
      Messages
    </a>
  </div>
  <div class="c-content-container" id="navContainer">
    <div class="container-fluid">
      <div class="row c-add-project-container">
        <div class="col-12">
          <h5>Edit Project: {{ projectDetailResult.title }}</h5>
        </div>
        <div class="col-xl-3 col-lg-12 col-md-12">
          <div class="mt-3">
            <input
              type="text"
              class="form-control c-add-project-input"
              placeholder="Title"
              [(ngModel)]="titleInput"
            />
            <label class="mt-3">Category:</label>
            <span
              *ngIf="categoryLoading"
              class="spinner-border my-3 d-block mx-auto"
            ></span>
            <select
              *ngIf="!categoryLoading"
              class="form-control c-add-project-input"
              (change)="selectChange($event)"
              [(ngModel)]="selectValue"
            >
              <option
                *ngFor="let category of categoriesResult"
                [value]="category.id"
                >{{ category.title }}</option
              >
            </select>
          </div>
          <div class="mt-3">
            <label>Video:</label>
            <span class="text-secondary d-block">Supported formats: mp4</span>
            <span
              *ngIf="videoUploadLoading"
              class="spinner-border my-3 d-block mx-auto"
            ></span>
            <button
              class="btn c-add-project-button mt-3 d-block mx-auto"
              (click)="deleteVideoClicked()"
              [disabled]="deleteVideoDisabled"
            >
              Delete Video
            </button>
            <button
              class="btn c-add-project-button mt-3 d-block mx-auto"
              (click)="videoInput.click()"
              [disabled]="videoUploadDisable || videoUploadLoading"
            >
              Upload Video
            </button>
            <input
              type="file"
              class="d-none"
              (change)="onFileSelectedVideo($event)"
              #videoInput
            />
            <label class="mt-3">Images:</label>
            <span
              *ngIf="uploadLoading"
              class="spinner-border my-3 d-block mx-auto"
            ></span>
            <button
              class="btn c-add-project-button d-block mx-auto mb-3"
              (click)="deleteImagesClicked()"
              [disabled]="deleteImagesDisabled"
            >
              Delete Images
            </button>
            <button
              class="btn c-add-project-button d-block mx-auto"
              (click)="imageInput.click()"
              [disabled]="uploadLoading"
            >
              Upload Image
            </button>
            <input
              type="file"
              class="d-none"
              (change)="onFileSelected($event)"
              #imageInput
            />
            <div class="c-add-project-images-container d-flex flex-wrap mt-3">
              <div
                *ngFor="let image of imageResponse"
                class="c-add-project-image"
              >
                <file-loader [imageId]="image"></file-loader>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-9 col-lg-12 col-md-12 c-add-project-wrap">
          <quill-editor [(ngModel)]="textInput" theme="snow"></quill-editor>
        </div>
        <div class="col-12">
          <span
            *ngIf="updateLoading"
            class="spinner-border my-3 d-block mx-auto"
          ></span>
          <button
            (click)="editProjectClicked()"
            [disabled]="updateLoading"
            class="btn c-add-project-button mt-3 d-block mx-auto"
          >
            Update Changes
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
